import { render, staticRenderFns } from "./AnnualKappaVegasGalaFundraiser52nd.vue?vue&type=template&id=24a426ec&scoped=true"
import script from "./AnnualKappaVegasGalaFundraiser52nd.vue?vue&type=script&lang=js"
export * from "./AnnualKappaVegasGalaFundraiser52nd.vue?vue&type=script&lang=js"
import style0 from "./AnnualKappaVegasGalaFundraiser52nd.vue?vue&type=style&index=0&id=24a426ec&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24a426ec",
  null
  
)

export default component.exports