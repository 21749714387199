<template>
  <div style="background-color: #ffffff">
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />

    <nu-modal ref="smodal" title="">
      <successModal :showsuccess="true" @closemodal="closeModaldata()" />
    </nu-modal>

    <nu-modal ref="modal">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2rem;
                    color: black;
                  "
                >
                  Reservation Form
                </h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  color: black;
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.1em;
                    color: black;
                  "
                  >Ticket Title:</b
                >
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p
                v-if="!redeemData"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  color: black;
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.1em;
                    color: black;
                  "
                  >Per Person Price:</b
                >
                ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p
                v-if="redeemData"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  color: black;
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.1em;
                    color: black;
                  "
                  >Per Person Price:</b
                >
                ${{ redeemData.price.toFixed(2) }}
              </p>
              <p
                v-if="!redeemData"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  color: black;
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.1em;
                    color: black;
                  "
                >
                  Total Price:
                </b>
                ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p
                v-if="redeemData"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  color: black;
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.1em;
                    color: black;
                  "
                >
                  Total Price:
                </b>
                ${{ ((redeemData.price || 0) * tickcount).toFixed(2) }}
              </p>
            </div>
            <div class="col-md-6">
              <label
                class="mt-2"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
              >
                Full Name
              </label>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <label
                class="mt-2"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
              >
                Email Address
              </label>
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>

            <div class="col-md-6 mb-1">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >Phone
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter phone number"
                @keypress="isNumber()"
                v-model="userContactPhone"
              />
              <span
                v-bind:class="{ 'op-0': !(istouched && !invaliduphone) }"
                class="mb-3"
                style="color: red; font-size: 12px"
                >*please fill valid phone number</span
              >
            </div>

            <div class="col-md-6 mb-1">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >Zip Code
              </label>
              <input
                type="text"
                v-model="nZip"
                class="form-control"
                placeholder="Zip Code"
              />
            </div>

            <div class="col-md-6 mb-1">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >Number of Ticket(s)</label
              >
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <!-- <div class="col-md-6">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >Age Range</label
              >
              <select class="form-control" v-model="ageRange">
                <option
                  class="form-control"
                  v-bind:key="ageval + 'b'"
                  v-for="ageval in ageRangesList"
                  :value="ageval"
                >
                  {{ ageval }}
                </option>
              </select>
            </div> -->

            <!-- <div class="col-md-6">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >How did you hear about this event</label
              >
              <input
                type="text"
                class="form-control mb-3"
                placeholder=""
                v-model="hearAbout"
                required
              />
            </div> -->
            <div
              class="col-md-6 mb-1"
              v-if="
                ((TicketsResult[selected] || {}).availableSeating || [])
                  .length > 0 &&
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode == 'tk_NzrhJLxmsLhNLaD'
              "
            >
              <label class="mt-3">Available Seating</label>
              <select class="form-control" v-model="availSeating">
                <option
                  class="form-control"
                  v-bind:key="seating.table"
                  v-for="seating in uniq(
                    (TicketsResult[selected] || {}).availableSeating
                  )"
                  :value="seating"
                >
                  {{ seating }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-1"
              v-if="
                ((TicketsResult[selected] || {}).availableSeating || [])
                  .length > 0 &&
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode !== 'tk_Zp9izBaReeI9K6t' &&
                TicketsResult[selected].ticketCode !== 'tk_NzrhJLxmsLhNLaD'
              "
            >
              <label class="mt-3">Available Seating</label>
              <select class="form-control" v-model="availSeating">
                <option
                  class="form-control"
                  v-bind:key="seating.alias"
                  v-for="seating in (TicketsResult[selected] || {})
                    .availableSeating"
                  :value="seating.alias"
                >
                  {{ seating.alias }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn-UNCFGala btn-sm my-auto shimmer"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <div
              class="col-md-12 mt-2"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode == 'tk_Zp9izBaReeI9K6t'
              "
            >
              <doublelist
                @listtopush="listtopush"
                v-if="
                  TicketsResult[selected] &&
                  TicketsResult[selected].availableSeating.length > 0
                "
                :list="(TicketsResult[selected] || {}).availableSeating"
                :limit="(TicketsResult[selected] || {}).allowedPerRequest || 0"
              />
            </div>

            <div
              class="col-md-12 mt-4"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 1.15em;
                color: black;
              "
            >
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  (TicketsResult[selected] &&
                  groupData.includes(TicketsResult[selected].ticketCode)
                    ? !required_power_needs.trim().length
                    : false) ||
                  !fullname.trim().length ||
                  !nZip.trim().length ||
                  !invalidemail ||
                  !invaliduphone ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept ||
                  !nZip.trim().length
                "
                class="btn solid-btn-UNCFGala m-auto mr-2 shimmer"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div
      class="container-fluid p-0"
      style="overflow-x: hidden; background: #ffffff"
    >
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            id="full"
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_OHIOBLACKEXPO2025_V1.jpg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />

          <img
            id="half"
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/flyer_OHIOBLACKEXPO2025_V1.jpg"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div
        class="row w-100 m-0 promo-section MYLE-lite-green"
        style="background: #ff4938"
      >
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center mb-0"
                style="
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 700;
                  font-size: 2.8em;
                  color: white;
                "
              >
                2025 OHIO BLACK EXPO Riverfront Culture Fest
              </h1>

              <h1
                class="text-center mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                  color: white;
                "
              >
                Saturday, May 24, 2025 11:00AM - 11:00PM <br />
                Sunday, May 25, 12:00PM - 9:00PM
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 16px;
                  color: white;
                "
              >
                <a
                  href="https://maps.app.goo.gl/unSBRBJ3uZhc2Vsg6"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: white"
                >
                  Genoa Park <br />
                  303 W. Broad St., Columbus, OH 43215
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px; color: black"
      >
        <div class="container">
          <p
            id="d8"
            class="mt-4 mb-1"
            style="
              font-family: 'nunito', sans-serif;
              font-weight: 400;
              font-size: 1.2em;
            "
          >
            <span style="font-size: 1.6em; font-weight: 700">
              2024 Recap Video
            </span>
          </p>
          <video-embed
            css="embed-responsive-16by9"
            src="https://youtu.be/erKYK99QRDk?si=lpTgz1nHkvIs8G_E"
          ></video-embed>
        </div>

        <div class="row mt-5">
          <div class="col-md-6">
            <p class="text-center">
              <img
                id="i1"
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/flyer_OHIOBLACKEXPO2025_saturday_V1.jpg"
                style="border-radius: 0%; width: 100%; text-align: center"
                class="text-center"
              />
            </p>
          </div>

          <div class="col-md-6">
            <p class="text-center">
              <img
                id="i1"
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/flyer_OHIOBLACKEXPO2025_Sunday.jpg"
                style="border-radius: 0%; width: 100%; text-align: center"
                class="text-center"
              />
            </p>
          </div>
        </div>

        <hr />
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px; color: black"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content">
              <!-- <p
                id="d2"
                class="text-center mb-0"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 600;
                  font-size: 1.6em;
                  color: #ff4938;
                "
              >
                The Food. The Music. The Vibe. “There’s no other place to be
                Memorial Day Weekend!”
              </p> -->

              <p
                id="d3"
                class="mt-0"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 400;
                  font-size: 1.3em;
                "
              >
                <!-- Over 100 premier Black-owned businesses, artisans and food +
                beverage providers to support. Thousands of attendees from Ohio
                and beyond. Networking and relaxing in a family reunion type
                atmosphere conducive for families, couples, elders, and youth.
                Something for everyone.
                <br />
                <br />
                Get your tickets early! Prices increase as we get closer to Expo
                Week.
                <span style="font-weight: 700"
                  >Every ticket purchased supports youth and community
                  programs*.</span
                >
                <br />
                <br />
                🌟🎉 Kick off your summer festival season with the 2025 OHIO
                BLACK EXPO Riverfront Culture Fest! ✊🏾❤️
                <br />
                <br />
                📅 Mark your calendars and get your tickets! Tell friends and
                family! May 24-25, Downtown CBUS will once again transform into
                the ultimate celebration of culture, community, and connection
                at Genoa Park.
                <br />
                <br />

                🍴 Delicious food. 🎶 Live entertainment. 🛍️ Unique vendors. 🎈
                Enjoy activities for ALL ages in a safe, family-reunion
                atmosphere like no other!

                <br />
                <br />

                💥 Vendor and Parade Applications are NOW OPEN! Don’t miss your
                chance to be part of this unforgettable experience.

                <br />
                <br /> -->

                🌐 Visit

                <a
                  href="http://ohioblackexpo.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ohioblackexpo.com</a
                >

                for more details.

                <!-- and subscribe for updates. Follow us
                @blackexpollence on all platforms for the latest.

                <br />
                <br />

                🔥 There's no other place to be Memorial Day Weekend! -->

                <br />
                <br />

                *NO REFUNDS -
                <span style="font-weight: 700">
                  Your ticket supports youth and community programs.
                </span>
              </p>

              <div class="row mt-5">
                <div class="col-md-6">
                  <p class="text-center">
                    <img
                      id="i1"
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/flyer_OHIOBLACKEXPO2025_Teedra_Moses_V1.jpg"
                      style="border-radius: 0%; width: 100%; text-align: center"
                      class="text-center"
                    />
                  </p>
                </div>

                <div class="col-md-6">
                  <p class="text-center">
                    <img
                      id="i1"
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/flyer_OHIOBLACKEXPO2025_Marsha_Ambrosius_V1.jpg"
                      style="border-radius: 0%; width: 100%; text-align: center"
                      class="text-center"
                    />
                  </p>
                </div>
              </div>

              <!-- Tickets Section starts -->

              <!-- Tickets Section starts -->
              <div class="row justify-content-center">
                <div class="col-md-12">
                  <div class="py-5">
                    <div class="text-center">
                      <h3
                        class="w-auto d-inline-block mb-1"
                        style="
                          font-family: 'nunito', sans-serif;
                          font-size: 2em;
                          font-weight: 700;
                          color: #1fb745;
                        "
                      >
                        SPRING DISCOUNT
                      </h3>
                      <br />
                      <h3
                        class="w-auto d-inline-block mb-0"
                        style="
                          font-family: 'nunito', sans-serif;
                          font-size: 1.3em;
                          font-weight: 500;
                          color: black;
                        "
                      >
                        Available Apr 1 - Apr 30, 2025
                      </h3>
                    </div>
                    <div
                      id="d11"
                      class="d-flex flex-row flex-wrap justify-content-center"
                    >
                      <div
                        class="card mx-4 mt-4"
                        style="
                          max-width: 400px !important;
                          border-radius: 10px;
                          overflow: hidden;
                          box-shadow: 4px 4px #293d58;
                          border-color: #293d58;
                          color: black;
                        "
                        v-bind:key="objk"
                        v-for="(obj, objk) in individualSpringTick(
                          TicketsResult
                        )"
                      >
                        <p
                          v-if="obj.ticketCode == 'tk_4NMr2PFzC8gIX3U'"
                          class="text-center mt-1 mb-1"
                          style="
                            font-family: 'nunito', sans-serif;
                            font-size: 1em;
                            font-weight: 700;
                            line-height: 80%;
                            color: red;
                          "
                        >
                          Max (5) Per Person
                        </p>

                        <p
                          v-if="obj.ticketCode == 'tk_4nHntEyaOk1IFqB'"
                          class="text-center mt-1 mb-1"
                          style="
                            font-family: 'nunito', sans-serif;
                            font-size: 1em;
                            font-weight: 700;
                            line-height: 80%;
                            color: red;
                          "
                        >
                          Best Value
                        </p>

                        <p
                          v-if="obj.ticketCode == 'tk_H89UHC53ig8fZww'"
                          class="text-center mt-1 mb-1"
                          style="
                            font-family: 'nunito', sans-serif;
                            font-size: 1em;
                            font-weight: 700;
                            line-height: 80%;
                            color: red;
                          "
                        >
                          VIP
                        </p>
                        <div
                          class="card-header MYLE-green white text-center"
                          style="
                            background-color: #1fb745;
                            font-family: 'nunito', sans-serif;
                            font-size: 1.2em;
                            font-weight: 500;
                          "
                        >
                          {{ obj.ticketName }}
                        </div>
                        <div
                          class="card-body"
                          style="
                            display: flex;
                            flex-direction: column;
                            background: #ffffff;
                          "
                        >
                          <p
                            class="text-center"
                            style="
                              font-family: 'nunito', sans-serif;
                              font-size: 1.1em;
                              font-weight: 500;
                              line-height: 80%;
                            "
                            v-bind:key="w"
                            v-for="(msgs, w) in tkList[obj.ticketCode].messages"
                          >
                            <span v-if="w === 4" style="font-weight: 700">
                              {{ msgs }}
                            </span>

                            <span
                              v-if="
                                w === 5 &&
                                obj.ticketCode === 'tk_H89UHC53ig8fZww'
                              "
                              style="font-weight: 700"
                            >
                              {{ msgs }}
                            </span>

                            <span
                              v-if="w === 18"
                              style="font-weight: 700; font-size: 1.5em"
                            >
                              {{ msgs }}
                            </span>

                            <span
                              v-if="
                                w === 15 &&
                                obj.ticketCode === 'tk_H89UHC53ig8fZww'
                              "
                              style="font-weight: 700; font-size: 1.5em"
                            >
                              {{ msgs }}
                            </span>

                            <span
                              v-if="w != 4 && w != 18 && w != 15"
                              style="font-weight: 500"
                            >
                              {{ msgs }}
                            </span>
                          </p>
                          <div class="text-center" style="margin-top: auto">
                            <!-- <p>
                              <b
                                class="font-weight-bold"
                                style="
                                  font-family: 'nunito', sans-serif;
                                  font-size: 1.2em;
                                  font-weight: 500;
                                "
                                >Total Cost: </b
                              >${{ obj.price.toFixed(2) }}
                            </p> -->
                            <p
                              v-bind:key="linek"
                              v-for="(line, linek) in obj.description"
                            >
                              {{ line }}
                            </p>
                            <button
                              :disabled="
                                !obj.availableTickets ||
                                obj.status == 'EXPIRED' ||
                                obj.status == 'COMING_SOON'
                              "
                              v-bind:class="{
                                'bg-disable':
                                  obj.status == 'EXPIRED' ||
                                  !obj.availableTickets ||
                                  obj.status == 'COMING_SOON',
                              }"
                              class="btn solid-btn-UNCFGala shimmer"
                              @click="openmodel(obj)"
                              style="
                                pointer-events: all;
                                cursor: pointer;
                                font-size: 18px;
                              "
                            >
                              {{
                                obj.status == "EXPIRED" || !obj.availableTickets
                                  ? "Sold Out"
                                  : obj.status == "COMING_SOON"
                                  ? "Coming Soon"
                                  : "Purchase Now"
                              }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Tickets Section starts -->
              <div class="row justify-content-center">
                <div class="col-md-12">
                  <div class="py-5">
                    <div class="text-center">
                      <h3
                        class="w-auto d-inline-block mb-1"
                        style="
                          font-family: 'nunito', sans-serif;
                          font-size: 2em;
                          font-weight: 700;
                          color: #fbaf3d;
                        "
                      >
                        REGULAR ADMISSION
                      </h3>
                      <br />
                      <h3
                        class="w-auto d-inline-block mb-0"
                        style="
                          font-family: 'nunito', sans-serif;
                          font-size: 1.3em;
                          font-weight: 500;
                          color: black;
                        "
                      >
                        Available May 1 - May 23, 2025
                      </h3>
                    </div>
                    <div
                      id="d11"
                      class="d-flex flex-row flex-wrap justify-content-center"
                    >
                      <div
                        class="card mx-4 mt-4"
                        style="
                          max-width: 400px !important;
                          border-radius: 10px;
                          overflow: hidden;
                          box-shadow: 4px 4px #293d58;
                          border-color: #293d58;
                          color: black;
                        "
                        v-bind:key="objk"
                        v-for="(obj, objk) in individualRegularTick(
                          TicketsResult
                        )"
                      >
                        <div
                          class="card-header MYLE-green white text-center"
                          style="
                            background-color: #fbaf3d;
                            font-family: 'nunito', sans-serif;
                            font-size: 1.2em;
                            font-weight: 500;
                          "
                        >
                          {{ obj.ticketName }}
                        </div>
                        <div
                          class="card-body"
                          style="
                            display: flex;
                            flex-direction: column;
                            background: #ffffff;
                          "
                        >
                          <p
                            class="text-center"
                            style="
                              font-family: 'nunito', sans-serif;
                              font-size: 1.1em;
                              font-weight: 500;
                              line-height: 80%;
                            "
                            v-bind:key="w"
                            v-for="(msgs, w) in tkList[obj.ticketCode].messages"
                          >
                            <span v-if="w === 4" style="font-weight: 700">
                              {{ msgs }}
                            </span>

                            <span
                              v-if="w === 18"
                              style="font-weight: 700; font-size: 1.5em"
                            >
                              {{ msgs }}
                            </span>

                            <span
                              v-if="w != 4 && w != 18"
                              style="font-weight: 500"
                            >
                              {{ msgs }}
                            </span>
                          </p>
                          <div class="text-center" style="margin-top: auto">
                            <!-- <p>
                              <b
                                class="font-weight-bold"
                                style="
                                  font-family: 'nunito', sans-serif;
                                  font-size: 1.2em;
                                  font-weight: 500;
                                "
                                >Total Cost: </b
                              >${{ obj.price.toFixed(2) }}
                            </p> -->
                            <p
                              v-bind:key="linek"
                              v-for="(line, linek) in obj.description"
                            >
                              {{ line }}
                            </p>
                            <button
                              :disabled="
                                !obj.availableTickets ||
                                obj.status == 'EXPIRED' ||
                                obj.status == 'COMING_SOON'
                              "
                              v-bind:class="{
                                'bg-disable':
                                  obj.status == 'EXPIRED' ||
                                  !obj.availableTickets ||
                                  obj.status == 'COMING_SOON',
                              }"
                              class="btn solid-btn-UNCFGala shimmer"
                              @click="openmodel(obj)"
                              style="
                                pointer-events: all;
                                cursor: pointer;
                                font-size: 18px;
                              "
                            >
                              {{
                                obj.status == "EXPIRED" || !obj.availableTickets
                                  ? "Sold Out"
                                  : obj.status == "COMING_SOON"
                                  ? "Coming Soon"
                                  : "Purchase Now"
                              }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Tickets Section starts -->
              <div class="row justify-content-center">
                <div class="col-md-12">
                  <div class="py-5">
                    <div class="text-center">
                      <h3
                        class="w-auto d-inline-block mb-1"
                        style="
                          font-family: 'nunito', sans-serif;
                          font-size: 2em;
                          font-weight: 700;
                          color: #9901ff;
                        "
                      >
                        SAME DAY ADMISSION
                      </h3>
                      <br />
                      <h3
                        class="w-auto d-inline-block mb-0"
                        style="
                          font-family: 'nunito', sans-serif;
                          font-size: 1.3em;
                          font-weight: 500;
                          color: black;
                        "
                      >
                        Available May 24 - May 25, 2025
                      </h3>
                    </div>
                    <div
                      id="d11"
                      class="d-flex flex-row flex-wrap justify-content-center"
                    >
                      <div
                        class="card mx-4 mt-4"
                        style="
                          max-width: 400px !important;
                          border-radius: 10px;
                          overflow: hidden;
                          box-shadow: 4px 4px #293d58;
                          border-color: #293d58;
                          color: black;
                        "
                        v-bind:key="objk"
                        v-for="(obj, objk) in individualSameDayTick(
                          TicketsResult
                        )"
                      >
                        <div
                          class="card-header MYLE-green white text-center"
                          style="
                            background-color: #9901ff;
                            font-family: 'nunito', sans-serif;
                            font-size: 1.2em;
                            font-weight: 500;
                          "
                        >
                          {{ obj.ticketName }}
                        </div>
                        <div
                          class="card-body"
                          style="
                            display: flex;
                            flex-direction: column;
                            background: #ffffff;
                          "
                        >
                          <p
                            class="text-center"
                            style="
                              font-family: 'nunito', sans-serif;
                              font-size: 1.1em;
                              font-weight: 500;
                              line-height: 80%;
                            "
                            v-bind:key="w"
                            v-for="(msgs, w) in tkList[obj.ticketCode].messages"
                          >
                            <span v-if="w === 4" style="font-weight: 700">
                              {{ msgs }}
                            </span>

                            <span
                              v-if="w === 18"
                              style="font-weight: 700; font-size: 1.5em"
                            >
                              {{ msgs }}
                            </span>

                            <span
                              v-if="w != 4 && w != 18"
                              style="font-weight: 500"
                            >
                              {{ msgs }}
                            </span>
                          </p>
                          <div class="text-center" style="margin-top: auto">
                            <!-- <p>
                              <b
                                class="font-weight-bold"
                                style="
                                  font-family: 'nunito', sans-serif;
                                  font-size: 1.2em;
                                  font-weight: 500;
                                "
                                >Total Cost: </b
                              >${{ obj.price.toFixed(2) }}
                            </p> -->
                            <p
                              v-bind:key="linek"
                              v-for="(line, linek) in obj.description"
                            >
                              {{ line }}
                            </p>
                            <button
                              :disabled="
                                !obj.availableTickets ||
                                obj.status == 'EXPIRED' ||
                                obj.status == 'COMING_SOON'
                              "
                              v-bind:class="{
                                'bg-disable':
                                  obj.status == 'EXPIRED' ||
                                  !obj.availableTickets ||
                                  obj.status == 'COMING_SOON',
                              }"
                              class="btn solid-btn-UNCFGala shimmer"
                              @click="openmodel(obj)"
                              style="
                                pointer-events: all;
                                cursor: pointer;
                                font-size: 18px;
                              "
                            >
                              {{
                                obj.status == "EXPIRED" || !obj.availableTickets
                                  ? "Sold Out"
                                  : obj.status == "COMING_SOON"
                                  ? "Coming Soon"
                                  : "Purchase Now"
                              }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row justify-content-center mt-3" id="d5">
                <div class="col-md-12">
                  <div class="py-5">
                    <div class="text-center">
                      <h3
                        class="w-auto d-inline-block mb-1"
                        style="
                          font-family: 'nunito', sans-serif;
                          font-size: 2em;
                          font-weight: 700;
                          color: #252525;
                        "
                      >
                        BLACK HISTORY MONTH SPECIAL
                      </h3>
                      <br />
                      <h3
                        class="w-auto d-inline-block mb-0"
                        style="
                          font-family: 'nunito', sans-serif;
                          font-size: 1.3em;
                          font-weight: 500;
                          color: black;
                        "
                      >
                        Available Feb 1 - Feb 28, 2025
                      </h3>
                    </div>
                    <br />
                    <div
                      class="d-flex flex-row flex-wrap justify-content-center"
                    >
                      <div
                        class="card mx-4 mt-4"
                        style="
                          max-width: 400px !important;
                          border-radius: 10px;
                          overflow: hidden;
                          box-shadow: 4px 4px #293d58;
                          border-color: #293d58;
                          color: black;
                        "
                        v-bind:key="objk"
                        v-for="(obj, objk) in individualTick(TicketsResult)"
                      >
                        <div
                          class="card-header MYLE-green white text-center"
                          style="
                            background-color: #252525;
                            font-family: 'nunito', sans-serif;
                            font-size: 1.2em;
                            font-weight: 500;
                          "
                        >
                          {{ obj.ticketName }}
                        </div>
                        <div
                          class="card-body"
                          style="
                            display: flex;
                            flex-direction: column;
                            background: #ffffff;
                          "
                        >
                          <p
                            class="text-center"
                            style="
                              font-family: 'nunito', sans-serif;
                              font-size: 1.1em;
                              font-weight: 500;
                              line-height: 80%;
                            "
                            v-bind:key="w"
                            v-for="(msgs, w) in (tkList[obj.ticketCode] || [])
                              .messages"
                          >
                            <span v-if="w === 4" style="font-weight: 700">
                              {{ msgs }}
                            </span>

                            <span
                              v-if="w === 18"
                              style="font-weight: 700; font-size: 1.5em"
                            >
                              {{ msgs }}
                            </span>

                            <span
                              v-if="
                                w === 15 &&
                                obj.ticketCode === 'tk_H89UHC53ig8fZww'
                              "
                              style="font-weight: 700; font-size: 1.5em"
                            >
                              {{ msgs }}
                            </span>

                            <span
                              v-if="
                                w === 5 &&
                                obj.ticketCode === 'tk_H89UHC53ig8fZww'
                              "
                              style="font-weight: 700"
                            >
                              {{ msgs }}
                            </span>

                            <span
                              v-if="w != 4 && w != 18 && w != 15 && w != 5"
                              style="font-weight: 500"
                            >
                              {{ msgs }}
                            </span>
                          </p>
                          <div class="text-center" style="margin-top: auto">
                            <!-- <p>
                              <b
                                class="font-weight-bold"
                                style="
                                  font-family: 'nunito', sans-serif;
                                  font-size: 1.2em;
                                  font-weight: 500;
                                "
                                >Total Cost: </b
                              >${{ obj.price.toFixed(2) }}
                            </p> -->
                            <p
                              v-bind:key="linek"
                              v-for="(line, linek) in obj.description"
                            >
                              {{ line }}
                            </p>
                            <button
                              :disabled="
                                !obj.availableTickets ||
                                obj.status == 'EXPIRED' ||
                                obj.status == 'COMING_SOON'
                              "
                              v-bind:class="{
                                'bg-disable':
                                  obj.status == 'EXPIRED' ||
                                  !obj.availableTickets ||
                                  obj.status == 'COMING_SOON',
                              }"
                              class="btn solid-btn-UNCFGala shimmer"
                              @click="openmodel(obj)"
                              style="
                                pointer-events: all;
                                cursor: pointer;
                                font-size: 18px;
                              "
                            >
                              {{
                                obj.status == "EXPIRED" || !obj.availableTickets
                                  ? "Sales Ended"
                                  : obj.status == "COMING_SOON"
                                  ? "Coming Soon"
                                  : obj.ticketCode == "tk_W80d0MdnpmKEkXX"
                                  ? "Register Now"
                                  : "Purchase Now"
                              }}
                            </button>

                            <p
                              v-if="obj.ticketCode == 'tk_W80d0MdnpmKEkXX'"
                              class="text-center mt-3 mb-0"
                              style="
                                font-family: 'nunito', sans-serif;
                                font-size: 1em;
                                font-weight: 500;
                                line-height: 80%;
                              "
                            >
                              *Note: Free tickets have no re-entry
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-md-12">
                  <div class="py-5">
                    <div class="text-center">
                      <h3
                        class="w-auto d-inline-block mb-1"
                        style="
                          font-family: 'nunito', sans-serif;
                          font-size: 2em;
                          font-weight: 700;
                          color: #ff4938;
                        "
                      >
                        EARLY BIRD SPECIAL
                      </h3>
                      <br />
                      <h3
                        class="w-auto d-inline-block mb-0"
                        style="
                          font-family: 'nunito', sans-serif;
                          font-size: 1.3em;
                          font-weight: 500;
                          color: black;
                        "
                      >
                        Available Mar 1 - Mar 31, 2025
                      </h3>
                    </div>
                    <div
                      id="d11"
                      class="d-flex flex-row flex-wrap justify-content-center"
                    >
                      <div
                        class="card mx-4 mt-4"
                        style="
                          max-width: 400px !important;
                          border-radius: 10px;
                          overflow: hidden;
                          box-shadow: 4px 4px #293d58;
                          border-color: #293d58;
                          color: black;
                        "
                        v-bind:key="objk"
                        v-for="(obj, objk) in groupTickets(TicketsResult)"
                      >
                        <div
                          class="card-header MYLE-green white text-center"
                          style="
                            background-color: #ff4938;
                            font-family: 'nunito', sans-serif;
                            font-size: 1.2em;
                            font-weight: 500;
                          "
                        >
                          {{ obj.ticketName }}
                        </div>
                        <div
                          class="card-body"
                          style="
                            display: flex;
                            flex-direction: column;
                            background: #ffffff;
                          "
                        >
                          <p
                            class="text-center"
                            style="
                              font-family: 'nunito', sans-serif;
                              font-size: 1.1em;
                              font-weight: 500;
                              line-height: 80%;
                            "
                            v-bind:key="w"
                            v-for="(msgs, w) in tkList[obj.ticketCode].messages"
                          >
                            <span v-if="w === 4" style="font-weight: 700">
                              {{ msgs }}
                            </span>

                            <span
                              v-if="
                                w === 5 &&
                                obj.ticketCode === 'tk_H89UHC53ig8fZww'
                              "
                              style="font-weight: 700"
                            >
                              {{ msgs }}
                            </span>

                            <span
                              v-if="w === 18"
                              style="font-weight: 700; font-size: 1.5em"
                            >
                              {{ msgs }}
                            </span>

                            <span
                              v-if="
                                w === 15 &&
                                obj.ticketCode === 'tk_H89UHC53ig8fZww'
                              "
                              style="font-weight: 700; font-size: 1.5em"
                            >
                              {{ msgs }}
                            </span>

                            <span
                              v-if="w != 4 && w != 18 && w != 15"
                              style="font-weight: 500"
                            >
                              {{ msgs }}
                            </span>
                          </p>
                          <div class="text-center" style="margin-top: auto">
                            <!-- <p>
                              <b
                                class="font-weight-bold"
                                style="
                                  font-family: 'nunito', sans-serif;
                                  font-size: 1.2em;
                                  font-weight: 500;
                                "
                                >Total Cost: </b
                              >${{ obj.price.toFixed(2) }}
                            </p> -->
                            <p
                              v-bind:key="linek"
                              v-for="(line, linek) in obj.description"
                            >
                              {{ line }}
                            </p>
                            <button
                              :disabled="
                                !obj.availableTickets ||
                                obj.status == 'EXPIRED' ||
                                obj.status == 'COMING_SOON'
                              "
                              v-bind:class="{
                                'bg-disable':
                                  obj.status == 'EXPIRED' ||
                                  !obj.availableTickets ||
                                  obj.status == 'COMING_SOON',
                              }"
                              class="btn solid-btn-UNCFGala shimmer"
                              @click="openmodel(obj)"
                              style="
                                pointer-events: all;
                                cursor: pointer;
                                font-size: 18px;
                              "
                            >
                              {{
                                obj.status == "EXPIRED" || !obj.availableTickets
                                  ? "Sold Out"
                                  : obj.status == "COMING_SOON"
                                  ? "Coming Soon"
                                  : "Purchase Now"
                              }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-md-12">
                  <p class="text-center">
                    <img
                      id="i1"
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_1_OHIOBLACKEXPO2025_V1.jpg"
                      style="border-radius: 0%; width: 98%; text-align: center"
                      class="text-center"
                    />
                  </p>
                </div>
              </div>

              <div class="row mt-0">
                <div class="col-md-12">
                  <p class="text-center">
                    <img
                      id="i1"
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_collage_1_OHIOBLACKEXPO2025_V1.png"
                      style="border-radius: 0%; width: 100%; text-align: center"
                      class="text-center"
                    />
                  </p>
                </div>
              </div>

              <hr />

              <p
                id="d8"
                class="mt-4 mb-1"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 400;
                  font-size: 1.2em;
                "
              >
                <span style="font-size: 1.6em; font-weight: 700">
                  Why attend?
                </span>
              </p>

              <div class="row mt-0">
                <div class="col-md-12">
                  <p
                    id="d9"
                    class="mt-0"
                    style="
                      font-family: 'nunito', sans-serif;
                      font-weight: 500;
                      font-size: 1.2em;
                    "
                  >
                    • Your attendance supports our organization (a 501(c)(3)
                    non-profit), participating businesses, organizations, youth,
                    performing and visual artists. <br />
                    • Every ticket purchased supports youth and community
                    programs. <br />
                    • Enjoy central Ohio's largest exposition of Black
                    businesses and culture in a safe, mutli-generational, family
                    reunion atmosphere. <br />
                    • The food, the music, the vibe!!!!!
                  </p>
                </div>
              </div>

              <p
                id="10"
                class="mt-4"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 400;
                  font-size: 1.2em;
                "
              >
                <span style="font-size: 1.6em; font-weight: 700">
                  About the Host
                </span>

                <br />

                <span style="font-weight: 500">
                  The Ohio Black Expo is a 501(c)(3) nonprofit organization.
                </span>
              </p>

              <p
                id="10"
                class="mt-4"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 400;
                  font-size: 1.2em;
                "
              >
                <span style="font-size: 1.6em; font-weight: 700">
                  Purpose
                </span>

                <br />

                <span style="font-weight: 500">
                  We are committed to enhancing the lives, success, and
                  well-being of African Americans in Ohio. We drive change and
                  foster growth through initiatives that address critical
                  disparities in areas such as health, education, senior
                  support, business, technology, and youth engagement.

                  <br />

                  Our signature event, the Ohio Black Expo: Riverfront Culture
                  Fest, takes place every Memorial Day Weekend, to empower and
                  unite Ohio's 1.7 million Black residents and those beyond.
                  This annual gathering welcomes guests from across the nation
                  to engage in solution-focused workshops, build connections at
                  networking events, and celebrate the richness of Black
                  culture. With amazing food, the arts, and an unforgettable
                  atmosphere, there's no other place to be Memorial Day Weekend!
                  <br />
                  <br />

                  <a
                    href="https://ohioblackexpo.com/about-us"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click here</a
                  >
                  to learn more about our programs and initiatives.
                </span>
              </p>

              <p
                id="d8"
                class="mt-4 mb-0"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 400;
                  font-size: 1.3em;
                "
              >
                <span style="font-size: 1.5em; font-weight: 700"> FAQs </span>
              </p>

              <div class="row mt-0">
                <div class="col-md-12">
                  <p
                    id="d9"
                    class="mt-0"
                    style="
                      font-family: 'nunito', sans-serif;
                      font-weight: 400;
                      font-size: 1.2em;
                    "
                  >
                    • Children 10 and under are FREE. <br />
                    • Folded lawn chairs & seat cushions allowed <br />
                    • Event will take place rain or shine<br />
                    • This is a gated event with two (2) public entrances <br />
                    • Gate #1 is at Broad St. and Washington Blvd. <br />
                    • Gate #2 is at Rich St. bridge near Civic Center Dr. <br />
                    • NO Public access on southside of COSI (NO EXCEPTIONS)
                    <br />
                    • NO outside food or beverages <br />
                    • NO coolers or large bags <br />
                    • NO backpacks <br />
                    • NO weapons <br />
                    • NO refunds*<br />
                    <br />
                    * proceeds support youth and community programs
                  </p>
                </div>
              </div>

              <p
                id="10"
                class="mt-4 mr-0"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                "
              >
                <span style="font-weight: 700">
                  Follow us @blackexpollence
                </span>
              </p>

              <div class="mb-2">
                <a
                  href="https://www.facebook.com/blackexpollence"
                  target="_self"
                >
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_black_facebook.svg"
                    class="img-fluid mr-2"
                    style="width: 2em"
                  />
                </a>

                <a href="https://twitter.com/blackexpollence" target="_self">
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_black_x.svg"
                    class="img-fluid mr-2"
                    style="width: 2em"
                  />
                </a>

                <a
                  href="https://www.instagram.com/blackexpollence"
                  target="_self"
                >
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_black_instagram.svg"
                    class="img-fluid mr-2"
                    style="width: 2em"
                  />
                </a>

                <a
                  href="https://www.linkedin.com/company/blackexpollence/"
                  target="_self"
                >
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_black_linkedin.png"
                    class="img-fluid mr-2"
                    style="width: 2em"
                  />
                </a>

                <a
                  href="https://www.youtube.com/@blackexpollence"
                  target="_self"
                >
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_black_youtube.svg"
                    class="img-fluid mr-2"
                    style="width: 2em"
                  />
                </a>

                <a
                  href="https://www.tiktok.com/@blackexpollence"
                  target="_self"
                >
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_black_tiktok.svg"
                    class="img-fluid mr-2"
                    style="width: 2em"
                  />
                </a>

                <a
                  href="https://www.threads.net/@blackexpollence"
                  target="_self"
                >
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_black_threads.svg"
                    class="img-fluid mr-2"
                    style="width: 2em"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row mt-5">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <p
          class="text-center"
          style="
            font-family: 'Open Sans', sans-serif;
            font-weight: 400;
            font-size: 1.1em;
            color: black;
          "
        >
          <img
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/flyer_OHIOBLACKEXPO2025_V1.jpg"
            style="border-radius: 0%; width: 90%"
          />
        </p>
      </div>
      <div class="col-md-3"></div>
    </div> -->

    <!-- <div class="text-center">
          <h3
            class="w-auto"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: 700;
              font-size: 40px;
              color: white;
            "
          >
            Watch The Video
          </h3>
        </div> -->
    <!-- <br />
        <div class="row" style="margin: auto; background: black">
          <div class="col-md-2 col-md-2"></div>
          <div class="col-md-8 col-md-8">
            <div class="text-center">
              <video-embed
                css="embed-responsive-16by9"
                src="https://www.youtube.com/watch?v=nmVB02laCc8"
              ></video-embed>
            </div>
          </div>
    
          <div class="col-md-2 col-md-2"></div>
        </div>
        <br />
        <br />
        <br /> -->

    <!-- <br />
      <section class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3 align-items-center"></div>
  
          <div class="col-md-6 col-lg-6 align-items-center">
            <div class="">
              <img
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/flyer_MIDWESTCIGARSOCIALPRESENTS2FAST2FURIOUS_V1.png"
                alt="Saturday Event"
                class="w-100 img-fluid"
              />
            </div>
          </div>
  
          <div class="col-md-3 col-lg-3 align-items-center"></div>
        </div>
      </section>
      <br /> -->

    <div
      class="row w-100 m-0 promo-section MYLE-lite-green"
      style="background: #ff4938"
    >
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center" style="color: white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row align-items-center justify-content-between test"
      style="margin: auto; margin-top: 20px"
    >
      <div class="col-lg-3 col-md-3">
        <img
          class="align-middle"
          src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/mock_OHIOBLACKEXPO2025_V1.png"
          style="width: 100%; max-width: 100%; height: auto"
        />
      </div>
      <div class="col-lg-9 col-md-9">
        <p
          class="mb-2 ml-4"
          style="
            font-family: 'nunito', sans-serif;
            font-weight: 400;
            font-size: 1.2em;
          "
        >
          Download the MYLE Events App via
          <a href="https://myle.com" target="_blank" rel="noopener noreferrer">
            www.myle.com</a
          >
          or find it in your iOS or Google Play Store under MYLE Events.
          <br />
          <br />

          The MYLE App offers convenient transportation options, integrating
          Waze, Apple Maps, Google Maps, Uber, and Lyft. It's your one-stop
          solution for a hassle-free travel experience.

          <br />
          <br />
          Note: Your tickets will be available on the MYLE App in the
          Account>Tickets section after purchase. You can easily access them via
          email (check spam if not received) or on the MYLE App for quick access
          into the event.
          <br />
          • Find all of the “Ohio Black Expo” events in MYLE<br />
          • Keep all of your digital tickets in MYLE for easy and fast
          check-in<br />
          • Help the Ohio Black Expo earn referral points – use referral code
          “OhioBlackExpo" during account set-up<br />
          • Receive important updates before and during the event<br />
          • Find other fun things to do in Ohio or on the way!<br />

          <br />
          🌟 We can't wait to see you 🌟

          <br />
        </p>
      </div>
    </div>
    <br />

    <div class="row w-100 m-0 promo-section">
      <div class="col-lg-12 col-md-12 mt-5">
        <div class="mt-3">
          <div class="section-heading mb-5">
            <h2
              class="text-center"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 15px;
              "
            >
              Powered by <a target="_blank" href="https://myle.com/">MYLE</a>
            </h2>
          </div>
        </div>
      </div>
    </div>

    <!-- <br />
    <site-footer :show-subscribe="true" /> -->
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
// import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
import successModal from "./Success/Success.vue";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      istouched: false,
      userContactPhone: "",
      ageRangesList: ["21-29", "30-39", "40-49", "50+"],
      ageRange: "21-29",
      selectionList: [],
      dict: [],
      availSeating: "",
      required_power_needs: "No",
      selectedSizes: "Extra Small - XS",
      sizes: [
        "Extra Small - XS",
        "Small - S",
        "Medium - M",
        "Large - L",
        "Extra Large - XL",
        "2 Extra Large - 2XL",
        "3 Extra Large - 3XL",
        "4 Extra Large - 4XL",
        "5 Extra Large - 5XL",
      ],
      individualsData: [
        "tk_W80d0MdnpmKEkXX",
        "tk_9YQ527mIS9OYj5L",
        // "tk_H89UHC53ig8fZww",
      ],
      groupData: [
        "tk_sBzRo0gTTMbjBnS",
        "tk_AzhZQLJCRmHj8Kk",
        // "tk_H89UHC53ig8fZww",
      ],
      individualSpringData: [
        "tk_4NMr2PFzC8gIX3U",
        "tk_4nHntEyaOk1IFqB",
        "tk_H89UHC53ig8fZww",
      ],
      individualRegularData: [
        "tk_BTmXd84j5J0gqml",
        "tk_tXV7me4jsRiM55N",
        // "tk_H89UHC53ig8fZww",
      ],
      individualSameDayData: [
        "tk_CzwWVAc1xRBX6a3",
        "tk_xcsBgxPN7vdDvt3",
        // "tk_H89UHC53ig8fZww",
      ],

      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        tk_W80d0MdnpmKEkXX: {
          messages: [
            "Admits (1) for a single day",
            "All day access",
            "May 24 or May 25",
            "",
            "Access to:",
            "Food Trucks",
            "100+ Vendors",
            "Concert",
            "Kickoff Parade",
            "Craft Beverages",
            "Family Zone",
            "Mini Yoga Fest",
            "Entertainment",
            "Activity Zones",
            "... and more!",
            "",
            "Rain or Shine",
            "",
            "$0",
            // "Processing fee of $0",
          ],
        },

        tk_9YQ527mIS9OYj5L: {
          messages: [
            "Admits (1) for both days",
            "All day access",
            "May 24 and May 25",
            "",
            "Access to:",
            "Food Trucks",
            "100+ Vendors",
            "Concert",
            "Kickoff Parade",
            "Craft Beverages",
            "Family Zone",
            "Mini Yoga Fest",
            "Entertainment",
            "Activity Zones",
            "... and more!",
            "",
            "Rain or Shine",
            "",
            "$10",
            // "Processing fee of $2.31",
          ],
        },
        tk_sBzRo0gTTMbjBnS: {
          messages: [
            "Admits (1) for a single day",
            "All day access",
            "May 24 or May 25",
            "",
            "Access to:",
            "Food Trucks",
            "100+ Vendors",
            "Concert",
            "Kickoff Parade",
            "Craft Beverages",
            "Family Zone",
            "Mini Yoga Fest",
            "Entertainment",
            "Activity Zones",
            "... and more!",
            "",
            "Rain or Shine",
            "",
            "$15",
            // "Processing fee of $2.65",
          ],
        },
        tk_AzhZQLJCRmHj8Kk: {
          messages: [
            "Admits (1) for both days",
            "All day access",
            "May 24 and May 25",
            "",
            "Access to:",
            "Food Trucks",
            "100+ Vendors",
            "Concert",
            "Kickoff Parade",
            "Craft Beverages",
            "Family Zone",
            "Mini Yoga Fest",
            "Entertainment",
            "Activity Zones",
            "... and more!",
            "",
            "Rain or Shine",
            "",
            "$20",
            // "Processing fee of $2.98",
          ],
        },
        tk_4NMr2PFzC8gIX3U: {
          messages: [
            "Admits (1) for a single day",
            "All day access",
            "May 24 or May 25",
            "",
            "Access to:",
            "Food Trucks",
            "100+ Vendors",
            "Concert",
            "Kickoff Parade",
            "Craft Beverages",
            "Family Zone",
            "Mini Yoga Fest",
            "Entertainment",
            "Activity Zones",
            "... and more!",
            "",
            "Rain or Shine",
            "",
            "$20",
            // "Processing fee of $2.98",
          ],
        },
        tk_4nHntEyaOk1IFqB: {
          messages: [
            "Admits (1) for both days",
            "All day access",
            "May 24 and May 25",
            "",
            "Access to:",
            "Food Trucks",
            "100+ Vendors",
            "Concert",
            "Kickoff Parade",
            "Craft Beverages",
            "Family Zone",
            "Mini Yoga Fest",
            "Entertainment",
            "Activity Zones",
            "... and more!",
            "",
            "Rain or Shine",
            "",
            "$30",
            // "Processing fee of $3.65",
          ],
        },
        tk_BTmXd84j5J0gqml: {
          messages: [
            "Admits (1) for a single day",
            "All day access",
            "May 24 or May 25",
            "",
            "Access to:",
            "Food Trucks",
            "100+ Vendors",
            "Concert",
            "Kickoff Parade",
            "Craft Beverages",
            "Family Zone",
            "Mini Yoga Fest",
            "Entertainment",
            "Activity Zones",
            "... and more!",
            "",
            "Rain or Shine",
            "",
            "$20",
            // "Processing fee of $2.98",
          ],
        },
        tk_tXV7me4jsRiM55N: {
          messages: [
            "Admits (1) for both days",
            "All day access",
            "May 24 and May 25",
            "",
            "Access to:",
            "Food Trucks",
            "100+ Vendors",
            "Concert",
            "Kickoff Parade",
            "Craft Beverages",
            "Family Zone",
            "Mini Yoga Fest",
            "Entertainment",
            "Activity Zones",
            "... and more!",
            "",
            "Rain or Shine",
            "",
            "$35",
            // "Processing fee of $3.99",
          ],
        },
        tk_CzwWVAc1xRBX6a3: {
          messages: [
            "Admits (1) for a single day",
            "All day access",
            "May 24 or May 25",
            "",
            "Access to:",
            "Food Trucks",
            "100+ Vendors",
            "Concert",
            "Kickoff Parade",
            "Craft Beverages",
            "Family Zone",
            "Mini Yoga Fest",
            "Entertainment",
            "Activity Zones",
            "... and more!",
            "",
            "Rain or Shine",
            "",
            "$25",
            // "Processing fee of $3.32",
          ],
        },
        tk_xcsBgxPN7vdDvt3: {
          messages: [
            "Admits (1) for both days",
            "All day access",
            "May 24 and May 25",
            "",
            "Access to:",
            "Food Trucks",
            "100+ Vendors",
            "Concert",
            "Kickoff Parade",
            "Craft Beverages",
            "Family Zone",
            "Mini Yoga Fest",
            "Entertainment",
            "Activity Zones",
            "... and more!",
            "",
            "Rain or Shine",
            "",
            "$40",
            // "Processing fee of $4.32",
          ],
        },

        tk_H89UHC53ig8fZww: {
          messages: [
            "Admits (1) for both days",
            "All day access",
            "May 24 and May 25",
            "",
            "Access to:",
            "",
            "Exclusive VIP Seating",
            "(2) Drink Tickets",
            "Private Bar Access",
            "Catered Appetizers",
            "... and more!",
            "",
            "",
            "Rain or Shine",
            "",
            "$150",
            // "Processing fee of $11.7",
          ],
        },
      },

      priceIds: {
        tk_W80d0MdnpmKEkXX: "price_1QdHwQD0vTZ4QB9cKJgEyYp4",
        tk_9YQ527mIS9OYj5L: "price_1QjqOID0vTZ4QB9cTwe0zLsU",
        tk_sBzRo0gTTMbjBnS: "price_1QjkddD0vTZ4QB9cIJ23OLWs",
        tk_AzhZQLJCRmHj8Kk: "price_1QjkdeD0vTZ4QB9c8yuwcJHF",
        tk_4NMr2PFzC8gIX3U: "price_1QjkdeD0vTZ4QB9cjn5TydwJ",
        tk_4nHntEyaOk1IFqB: "price_1QjkdfD0vTZ4QB9cNe3c8JXN",
        tk_BTmXd84j5J0gqml: "price_1QjkdfD0vTZ4QB9c5MpIDaKB",
        tk_tXV7me4jsRiM55N: "price_1QjkdgD0vTZ4QB9cy3i5AwfT",
        tk_CzwWVAc1xRBX6a3: "price_1Qjm5qD0vTZ4QB9cmq70Okum",
        tk_xcsBgxPN7vdDvt3: "price_1Qjm5qD0vTZ4QB9cLDK56nVG",
        tk_H89UHC53ig8fZww: "price_1QnZqBD0vTZ4QB9cwJ6dKope",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      nZip: "",
      hearAbout: "",
      tickcount: 1,
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    // SiteFooter,
    VueElementLoading,
    successModal,
    // VueFlux,
    // FluxPreloader,
    // FluxControls,
    StripeCheckout,
  },

  methods: {
    closeModaldata() {
      this.$refs.smodal.close();
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    listtopush(rr) {
      this.selectionList = [...rr];
    },
    uniq(list) {
      if (list && list.length > 0) {
        const uniqValues = {};
        list.forEach((v) => {
          uniqValues[v.table] = true;
        });

        return Object.keys(uniqValues);
      }

      return [];
    },
    datad1(listdata) {
      const seat = {
        1: 1200,
        2: 600,
        3: 400,
        4: 300,
        5: 240,
        6: 200,
        7: 171.4285714,
        8: 150,
        9: 133.33333,
        10: 120,
        11: 109.1,
      };
      const texts = [
        [1, 6, 7, 11, 12, 17, 18],
        [2, 5, 8, 10, 13, 16, 19],
        [3, 4, 9, 14, 15, 20],
      ];
      const distances = [0, 0, 20];
      const nodes = [];
      for (let y = 1; y <= 3; y++) {
        for (let i = 1; i <= 7; i++) {
          if (texts[y - 1][i - 1]) {
            nodes.push({
              x: 70 * i + distances[y - 1],
              y: 70 * y,
              val: seat[8],
              distance: i * 10,
              ydistance: y * 10,
              selected: false,
              text: texts[y - 1][i - 1],
              availableSeats: [],
            });
          }
        }
      }

      let recs = [];
      this.dict = {};
      listdata.forEach((r) => {
        recs = [...recs, ...r.availableSeating];
      });

      recs.forEach((r) => {
        const tableRecord = r.table.split("Table ")[1];
        const seatRecord = r.seat.split("Seat ")[1];

        if (!this.dict[tableRecord]) {
          this.dict[tableRecord] = [];
        }

        this.dict[tableRecord].push(Number(seatRecord - 1));
      });
      return nodes;
    },
    drawseatsd1(listdata) {
      const vm = this;
      const svg = window.d3
        .select("div#d1")
        .append("svg")
        .attr("width", "600px")
        .attr("height", "300px");
      const innerCircleRadius = 15;
      const outerCircleRadius = 15;
      const outerCircleRadius1 = 15;
      this.datad1(listdata).forEach((v) => {
        const originX = v.x + v.distance;
        const originY = v.y + v.ydistance;
        svg.append("circle").attr({
          cx: originX,
          cy: originY,
          r: innerCircleRadius,
          fill: v.selected ? "yellow" : "none",
          stroke: "black",
        });

        svg
          .append("text")
          .attr({
            x:
              originX -
              (Number(v.text) > 9 ? (Number(v.text) > 99 ? 12 : 9) : 3),
            y:
              originY +
              (Number(v.text) > 9 ? (Number(v.text) > 99 ? 5 : 5) : 5),
            "font-size": 15,
          })
          .text(v.text);
        console.log("--------");
        for (let i = 0; i < 1200; i += v.val) {
          const chairOriginX1 =
            originX - 3 + (outerCircleRadius1 + 16) * Math.sin(i);
          const chairOriginY1 =
            originY + 2 - (outerCircleRadius1 + 16) * Math.cos(i);
          const chairOriginX = originX + (outerCircleRadius + 5) * Math.sin(i);
          const chairOriginY = originY - (outerCircleRadius + 5) * Math.cos(i);

          svg.append("circle").attr({
            cx: i == 1200 ? Math.ceil(chairOriginX + 1) : chairOriginX,
            cy: chairOriginY,
            r: 4,
            fill:
              vm.dict[v.text] &&
              vm.dict[v.text].includes(i > 0 ? Math.ceil(i / v.val) : 0)
                ? v.text == "1" || v.text == "9"
                  ? "#0ea293"
                  : "#a19140"
                : "lightgrey",
          });

          svg
            .append("text")
            .attr({
              x: chairOriginX1,
              y: chairOriginY1,
              stroke: "black",
              "font-size": 10,
            })
            .text(() => {
              return i > 0 ? Math.ceil(i / v.val) + 1 : 1;
            });
        }
      });

      /// rects
      svg
        .append("rect")
        .attr("width", 50)
        .attr("height", 40)
        .attr("x", 300)
        .attr("fill", "none")
        .attr("stroke", "black");
      svg
        .append("text")
        .attr("x", 306.5)
        .attr("y", 25)
        .text("Stage")
        .attr("fill", "none")
        .attr("stroke", "black");

      svg
        .append("rect")
        .attr("width", 40)
        .attr("height", 40)
        .attr("y", 100)
        .attr("fill", "none")
        .attr("stroke", "black");
      svg
        .append("text")
        .attr("y", 125)
        .attr("x", 2.5)
        .text("Band")
        .attr("fill", "none")
        .attr("stroke", "black");
    },
    individualTick(list) {
      return list.filter((listRecord) =>
        this.individualsData.includes(listRecord.ticketCode)
      );
    },
    groupTickets(list) {
      return list.filter((listRecord) =>
        this.groupData.includes(listRecord.ticketCode)
      );
    },

    individualSpringTick(list) {
      return list.filter((listRecord) =>
        this.individualSpringData.includes(listRecord.ticketCode)
      );
    },
    individualRegularTick(list) {
      return list.filter((listRecord) =>
        this.individualRegularData.includes(listRecord.ticketCode)
      );
    },

    individualSameDayTick(list) {
      return list.filter((listRecord) =>
        this.individualSameDayData.includes(listRecord.ticketCode)
      );
    },

    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (mod) {
      let idx = 0;
      this.TicketsResult.forEach((i, i1) => {
        if (i.ticketCode == mod.ticketCode) {
          idx = i1;
        }
      });
      if (
        this.TicketsResult[idx].availableSeating &&
        this.TicketsResult[idx].availableSeating[0]
      ) {
        this.availSeating = this.TicketsResult[idx].availableSeating[0].alias;
      } else {
        this.availSeating = "";
      }

      if (
        this.TicketsResult[idx].availableSeating &&
        this.TicketsResult[idx].availableSeating[0] &&
        this.TicketsResult[idx].ticketCode == "tk_NzrhJLxmsLhNLaD"
      ) {
        this.availSeating = this.TicketsResult[idx].availableSeating[0].table;
      }
      this.selectionList = [];
      this.redeemData = null;
      this.redeemPriceId = "";
      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
      };

      payloadToPush.extraInfo = {
        zipcode: this.nZip,
        phone: this.userContactPhone,
        // hearAbout: this.hearAbout,
        // ageRange: this.ageRange,
      };

      if (vm.groupData.includes(vm.TicketsResult[vm.selected].ticketCode)) {
        payloadToPush.extraInfo["required_power_needs"] =
          vm.required_power_needs;
      }

      if (vm.availSeating.length > 0) {
        payloadToPush.additionalInfo = {
          type: "Seating",
          value: this.availSeating,
        };
      }

      const req =
        "https://myle-19701.uc.r.appspot.com/api/" +
        ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
          !this.redeemData) ||
        (this.redeemData && this.redeemData.price !== 0)
          ? "requested_tickets"
          : "requested_free_tickets");
      axios
        .post(req, payloadToPush)
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (vm.redeemData && vm.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.ageRange = "21-29";
            vm.fullname = "";
            vm.email = "";
            vm.userContactPhone = "";
            vm.hearAbout = "";
            vm.nZip = "";
            vm.isSubmitted = false;
            vm.$refs.smodal.open();
          }

          setTimeout(() => {
            vm.isSubmitted = false;
          }, 60000);
          vm.reserveFormAccept = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      const payloadToPush = {
        eventId: "HmspfkGsOKzupf",
      };
      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsoredEventTicketsWithSeating",
          payloadToPush
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          const objtmp = {
            tk_W80d0MdnpmKEkXX: null,
            tk_sBzRo0gTTMbjBnS: null,
            tk_AzhZQLJCRmHj8Kk: null,
            tk_4NMr2PFzC8gIX3U: null,
            tk_4nHntEyaOk1IFqB: null,
            tk_BTmXd84j5J0gqml: null,
            tk_tXV7me4jsRiM55N: null,
            tk_CzwWVAc1xRBX6a3: null,
            tk_xcsBgxPN7vdDvt3: null,
            tk_9YQ527mIS9OYj5L: null,
            tk_H89UHC53ig8fZww: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr;
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;

          // vm.drawseatsd1(vm.eventTicketResult);
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  mounted() {
    const isScrolledIntoView = (el) => {
      var top = el.offsetTop;
      var left = el.offsetLeft;
      var width = el.offsetWidth;
      var height = el.offsetHeight;

      while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
      }

      return (
        top < window.pageYOffset + window.innerHeight &&
        left < window.pageXOffset + window.innerWidth &&
        top + height > window.pageYOffset &&
        left + width > window.pageXOffset
      );
    };

    window.addEventListener("scroll", function () {
      let container = document.querySelector("#d1");
      if (isScrolledIntoView(container)) {
        container.classList.add("inView");
      }
      container = document.querySelector("#d2");
      if (isScrolledIntoView(container)) {
        container.classList.add("inView");
      }
      container = document.querySelector("#d3");
      if (isScrolledIntoView(container)) {
        container.classList.add("inView");
      }
      container = document.querySelector("#d4");
      if (isScrolledIntoView(container)) {
        container.classList.add("inView");
      }
      container = document.querySelector("#d5");
      if (isScrolledIntoView(container)) {
        container.classList.add("inView");
      }

      container = document.querySelector("#d6");
      if (isScrolledIntoView(container)) {
        container.classList.add("inView");
      }
      container = document.querySelector("#d7");
      if (isScrolledIntoView(container)) {
        container.classList.add("inView");
      }
      container = document.querySelector("#d8");
      if (isScrolledIntoView(container)) {
        container.classList.add("inView");
      }
      container = document.querySelector("#d9");
      if (isScrolledIntoView(container)) {
        container.classList.add("inView");
      }
      container = document.querySelector("#d11");
      if (isScrolledIntoView(container)) {
        container.classList.add("inView");
      }

      container = document.querySelector("#i1");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromside");
      }

      container = document.querySelector("#i2");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromsideo");
      }

      container = document.querySelector("#i3");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromside");
      }

      container = document.querySelector("#i4");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromsideo");
      }

      container = document.querySelector("#i5");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromside");
      }

      container = document.querySelector("#i6");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromsideo");
      }

      container = document.querySelector("#i7");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromside");
      }

      container = document.querySelector("#i8");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromsideo");
      }

      container = document.querySelector("#i9");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromside");
      }

      container = document.querySelector("#i10");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromsideo");
      }

      container = document.querySelector("#i11");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromside");
      }

      container = document.querySelector("#i12");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromsideo");
      }

      container = document.querySelector("#i13");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromside");
      }

      container = document.querySelector("#i14");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromsideo");
      }

      container = document.querySelector("#i15");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromside");
      }

      container = document.querySelector("#i16");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromsideo");
      }

      container = document.querySelector("#i17");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromside");
      }

      container = document.querySelector("#i18");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromsideo");
      }

      container = document.querySelector("#i19");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromside");
      }

      // container = document.querySelector("#imagesection1");
      // if (isScrolledIntoView(container)) {
      //   container.classList.add("fromsideo");
      // }
      // container = document.querySelector("#imagesectio2");
      // if (isScrolledIntoView(container)) {
      //   container.classList.add("fromside");
      // }
    });

    this.fetchAllTickets();
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },

    invaliduphone() {
      if (this.userContactPhone.length <= 0) {
        return false;
      }
      return (
        (this.userContactPhone || "").match(/\d/g) &&
        (this.userContactPhone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE - 2025 OHIO BLACK EXPO Riverfront Culture Fest",
    meta: [
      {
        name: "description",
        content: "MYLE - 2025 OHIO BLACK EXPO Riverfront Culture Fest",
      },

      {
        name: "keywords",
        content: "MYLE - 2025 OHIO BLACK EXPO Riverfront Culture Fest",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content:
          "https://myle.com/2025-ohio-black-expo-riverfront-culture-fest",
      },
    ],
  },
};
</script>

<style scoped>
.velmld-parent {
  background: #ffffff !important;
}

#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.cgreen {
  background: #0ea293;
  border: 1px solid #0ea293 !important;
}

.bg-disable {
  background: #0c3020;
  border: 1px solid #0c3020;
  color: white !important;
  pointer-events: none;
}

.bolded {
  font-weight: bold;
  font-size: 1.2em;
}

@media (max-width: 768px) {
  #redeem {
    text-align: center;
  }
}

@media (max-width: 622px) {
  #d1 {
    overflow-x: scroll;
  }
}
@media (min-width: 622.1px) {
  #d1 {
    overflow-x: hidden;
  }
}

.shimmer {
  display: inline-block;
  mask: linear-gradient(-60deg, #000 30%, rgba(179, 255, 0, 0.333), #000 70%)
    right/350% 100%;
  animation: shimmer 2.5s infinite;
}

@keyframes shimmer {
  100% {
    mask-position: left;
  }
}

.shimmer {
  display: inline-block;
  mask: linear-gradient(-60deg, #000 30%, rgba(179, 255, 0, 0.333), #000 70%)
    right/350% 100%;
  animation: shimmer 2.5s infinite;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.fromside {
  animation-duration: 1s; /* the duration of the animation */
  animation-timing-function: ease-out; /* how the animation will behave */
  animation-delay: 0s; /* how long to delay the animation from starting */
  animation-iteration-count: 1; /* how many times the animation will play */
  animation-name: slideInFromLeft; /* the name of the animation we defined above */
}

@keyframes slideInFromLefto {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.fromsideo {
  animation-duration: 1s; /* the duration of the animation */
  animation-timing-function: ease-out; /* how the animation will behave */
  animation-delay: 0s; /* how long to delay the animation from starting */
  animation-iteration-count: 1; /* how many times the animation will play */
  animation-name: slideInFromLefto; /* the name of the animation we defined above */
}

@keyframes shimmer {
  100% {
    mask-position: left;
  }
}

@keyframes example {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.1;
  }
  20% {
    opacity: 0.2;
  }
  30% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.5;
  }
  70% {
    opacity: 0.7;
  }
  80% {
    opacity: 0.8;
  }
  90% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

.inView {
  animation-name: example;
  animation-duration: 2.5s;
}

@media (min-width: 426px) {
  #half {
    display: none;
  }

  #full {
    display: block;
  }
}

@media (max-width: 425px) {
  #full {
    display: none;
  }

  #half {
    display: block;
  }
}
</style>
