<template>
  <div style="background-color: #ffffff">
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <!-- conifrm model -->
    <nu-modal ref="confirmmodal" title="">
      <confirmationPopup :showsuccess="showsuccess" @closemodal="closeModal" />
    </nu-modal>
    <!-- confrim model -->

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />
    <nu-modal ref="modal">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2rem;
                    color: black;
                  "
                >
                  Reservation Form
                </h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  color: black;
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.1em;
                    color: black;
                  "
                  >Ticket Title:</b
                >
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p
                v-if="
                  !redeemData &&
                  ((TicketsResult[selected] || {}).availableSeating || [])
                    .length > 0 &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketCode == 'tk_xc1WPgVRZof4PEm'
                "
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  color: black;
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.1em;
                    color: black;
                  "
                  >Per Person Price:</b
                >
                ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p
                v-if="redeemData"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  color: black;
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.1em;
                    color: black;
                  "
                  >Per Person Price:</b
                >
                ${{ redeemData.price.toFixed(2) }}
              </p>
              <p
                v-if="!redeemData"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  color: black;
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.1em;
                    color: black;
                  "
                >
                  Total Price:
                </b>
                ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p
                v-if="redeemData"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  color: black;
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.1em;
                    color: black;
                  "
                >
                  Total Price:
                </b>
                ${{ ((redeemData.price || 0) * tickcount).toFixed(2) }}
              </p>
            </div>
            <div class="col-md-6">
              <label
                class="mt-2"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
              >
                Full Name
              </label>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <label
                class="mt-2"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
              >
                Email Address
              </label>
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div
              class="col-md-6 mb-1"
              v-if="
                ((TicketsResult[selected] || {}).availableSeating || [])
                  .length > 0 &&
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode == 'tk_xc1WPgVRZof4PEm'
              "
            >
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >Available Seating</label
              >
              <select class="form-control" v-model="availSeating">
                <option
                  class="form-control"
                  v-bind:key="seating.table"
                  v-for="seating in uniq(
                    (TicketsResult[selected] || {}).availableSeating
                  )"
                  :value="seating"
                >
                  {{ seating }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-1"
              v-if="
                ((TicketsResult[selected] || {}).availableSeating || [])
                  .length > 0 &&
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode == 'tk_xc1WPgVRZof4PEm'
              "
            >
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >Number of Ticket(s)</label
              >
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="seating.table"
                  v-for="seating in uniqV(
                    (TicketsResult[selected] || {}).availableSeating,
                    availSeating
                  )"
                  :value="seating"
                >
                  {{ seating }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-1"
              v-if="
                ((TicketsResult[selected] || {}).availableSeating || [])
                  .length > 0 &&
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode != 'tk_xc1WPgVRZof4PEm'
              "
            >
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >Number of Ticket(s)</label
              >
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div class="col-md-6">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >Phone
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter phone number"
                @keypress="isNumber()"
                v-model="userContactPhone"
              />
              <span
                v-bind:class="{ 'op-0': !(istouched && !invaliduphone) }"
                class="mb-3"
                style="color: red; font-size: 12px"
                >*please fill valid phone number</span
              >
            </div>

            <div class="col-md-6">
              <label
                v-if="
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketCode != 'tk_xc1WPgVRZof4PEm'
                "
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >Business Name
              </label>

              <label
                v-if="
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketCode == 'tk_xc1WPgVRZof4PEm'
                "
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >Business Name (optional)
              </label>

              <input
                type="text"
                class="form-control mb-3"
                placeholder="Business Name"
                required
                v-model="bname"
              />
            </div>

            <div
              class="col-md-6"
              v-if="
                ((TicketsResult[selected] || {}).availableSeating || [])
                  .length > 0 &&
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode != 'tk_xc1WPgVRZof4PEm'
              "
            >
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >Organization Affiliation (optional)</label
              >
              <input
                type="text"
                class="form-control mb-3"
                placeholder=""
                v-model="orgName"
                required
              />
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.1em;
                  color: black;
                "
                >Do you have a promo code?</label
              >

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn-100BMODSouth btn-sm my-auto shimmer"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 700;
                  font-size: 1em;
                  font-weight: bold;
                "
              >
                Redeem Code
              </button>
            </div>

            <div
              class="col-md-12 mt-2"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode == 'tk_Zp9izBaReeI9K6t'
              "
            >
              <doublelist
                @listtopush="listtopush"
                v-if="
                  TicketsResult[selected] &&
                  TicketsResult[selected].availableSeating.length > 0
                "
                :list="(TicketsResult[selected] || {}).availableSeating"
                :limit="(TicketsResult[selected] || {}).allowedPerRequest || 0"
              />
            </div>

            <div
              class="col-md-12 mt-4"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 1.15em;
                color: black;
              "
            >
              <label
                v-if="
                  ((TicketsResult[selected] || {}).availableSeating || [])
                    .length > 0 &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketCode == 'tk_xc1WPgVRZof4PEm'
                "
                class="mb-4"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 600;
                  font-size: 1em;
                  color: black;
                "
              >
                * Please note that your ticket purchase reserves a seat at a
                table but does not include an assigned seat. Seating is
                available on a first-come, first-served basis upon arrival at
                the table you purchased
              </label>

              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  (TicketsResult[selected] &&
                  groupData.includes(TicketsResult[selected].ticketCode)
                    ? !required_power_needs.trim().length
                    : false) ||
                  !fullname.trim().length ||
                  !invalidemail ||
                  !invaliduphone ||
                  isSubmitted ||
                  (TicketsResult[selected] &&
                  TicketsResult[selected].ticketCode != 'tk_xc1WPgVRZof4PEm'
                    ? !bname.trim().length
                    : false) ||
                  !tickcountlist.length ||
                  !reserveFormAccept
                "
                class="btn solid-btn-100BMODSouth m-auto mr-2 shimmer"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 700;
                  font-size: 1em;
                  font-weight: bold;
                "
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div
      class="container-fluid p-0"
      style="overflow-x: hidden; background: #ffffff"
    >
      <!-- Navbar section starts -->
      <!-- <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div> -->
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_BlackMenofSouthMetro35thAnnualCelebrationofExcellenceScholarshipGalaMain_V1.png"
            style="width: 100%; max-width: 100%; height: auto"
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div
        class="row w-100 m-0 promo-section MYLE-lite-green"
        style="background: #d8b146"
      >
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  color: #ffffff;
                "
              >
                100 Black Men of South Metro Atlanta 35th Annual Celebration of
                Excellence Scholarship Gala
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                  color: #ffffff;
                "
              >
                🗓️ Saturday, April 26, 2025
                <br />
                ⏰ Starts at 5:00 PM
                <!-- - 11:00 PM -->
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                  color: #ffffff;
                "
              >
                <a
                  href="https://maps.app.goo.gl/A3nGyqacWCbYXs3x7"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: #ffffff"
                >
                  📍 Atlanta Airport Marriott Gateway
                  <br />
                  2020 Convention Center Concourse, Atlanta, GA 30337
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div class="section-heading mb-0 p-3" style="background: #faf9f6">
        <div class="text-center">
          <a
            href="#tickets"
            class="btn solid-btn-100BMODSouth ml-3 mr-3 mb-1 text-center"
            target="_self"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: 700;
              font-size: 1em;
              font-weight: bold;
            "
            rel="noopener noreferrer"
            >Buy Tickets</a
          >

          <a
            href="https://myle.com/100-black-men-of-south-metro-35th-annual-celebration-of-excellence-scholarship-gala-raffle"
            class="btn solid-btn-100BMODSouth ml-3 mr-3 mb-1 text-center"
            target="_self"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: 700;
              font-size: 1em;
              font-weight: bold;
            "
            rel="noopener noreferrer"
            >Buy Raffle Tickets</a
          >

          <a
            href="https://myle.com/100-black-men-of-south-metro-35th-annual-celebration-of-excellence-scholarship-gala-donation"
            class="btn solid-btn-100BMODSouth mb-1 ml-3 mr-3 text-center"
            target="_self"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: 700;
              font-size: 1em;
              font-weight: bold;
            "
            >Donate</a
          >

          <a
            :disabled="true"
            v-bind:class="{
              'bg-disable': true,
            }"
            href="#"
            class="btn solid-btn-100BMODSouth mb-1 ml-3 mr-3 text-center"
            target="_self"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: 700;
              font-size: 1em;
              font-weight: bold;
            "
            >Silent Auction</a
          >

          <a
            href="#sponsorship"
            class="btn solid-btn-100BMODSouth mb-1 ml-3 mr-3 text-center"
            target="_self"
            style="
              font-family: 'Montserrat', sans-serif;
              font-weight: 700;
              font-size: 1em;
              font-weight: bold;
            "
            >Sponsorship</a
          >

          <!-- <a
              href="https://myle.com/2024-scholarship-gala-weekend"
              class="btn solid-btn-100blackmen-inverted mb-1 ml-3 mr-3 text-center"
              target="_self"
              >Back To Main Page</a
            > -->

          <!-- <a
              href="https://myle.com/scholarship-gala-benefitting-the-leadership-academy-silent-auction"
              class="btn solid-btn-100blackmen-inverted ml-3 mr-3 mb-1 text-center"
              target="_blank"
              rel="noopener noreferrer"
              >Silent Auction</a
            > -->
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px; color: black"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content">
              <p
                id="d3"
                class="mt-5"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 400;
                  font-size: 1.3em;
                "
              >
                The 100 Black Men of South Metro Atlanta, Inc. will host its
                35th Annual Celebration of Excellence Scholarship Gala on
                Saturday, April 26, 2025.

                <br /><br />
                This year’s theme, “Empowering Excellence: Together We Rise,”
                highlights graduating seniors from mentorship programs and the
                achievements of program alumni who have excelled in their
                professional and personal lives.

                <br /><br />
                The event includes a keynote address, recognition of community
                impact, and celebration of scholarship recipients.

                <br /><br />

                The organization has awarded over $4 million in scholarships and
                programming since its inception.

                <br /><br />

                The gala supports mentorship initiatives including:
                <br />

                → The Rites of Passage Leadership Academy (for male students in
                grades 8-12, focusing on leadership, college/career
                preparedness, financial literacy, and life skills).
                <br />
                → M.I.R.R.OR. 100 Program (offering educational and occupational
                guidance to students).
                <br />
                → Collegiate 100 at Clark Atlanta University and Emerging 100
                for young professionals.

                <br /><br />

                Over 1,500 mentees have graduated from the South Metro Atlanta
                Rites of Passage Academy since 1990.
              </p>

              <div class="text-center mt-5">
                <h3
                  id="d6"
                  class="w-auto d-inline-block mb-1"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.8em;
                    color: black;
                  "
                >
                  Seating Plan
                </h3>
              </div>
              <div
                id="dx"
                style="
                  max-width: 1100px;
                  margin: auto;
                  height: auto;
                  margin-top: 50px;
                "
              ></div>

              <div
                class="row align-items-center justify-content-center test"
                style="margin: auto; margin-top: 20px"
              >
                <div
                  class="col-md-3 align-items-center justify-content-center"
                  style="display: flex"
                >
                  <table>
                    <tbody>
                      <tr>
                        <td style="vertical-align: middle">
                          <div
                            style="
                              background: #d8b146;
                              color: #d8b146;
                              width: 20px;
                              height: 20px;
                              margin-right: 3px;
                            "
                          ></div>
                        </td>
                        <td style="vertical-align: middle">
                          <div
                            style="
                              font-family: 'Quicksand', sans-serif;
                              font-weight: 500;
                              font-size: 12px;
                            "
                          >
                            Available For Purchase
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div
                  class="col-md-3 align-items-center justify-content-center"
                  style="display: flex"
                >
                  <table>
                    <tbody>
                      <tr>
                        <td style="vertical-align: middle">
                          <div
                            style="
                              background: lightgrey;
                              color: lightgrey;
                              width: 20px;
                              height: 20px;
                              margin-right: 3px;
                            "
                          ></div>
                        </td>
                        <td style="vertical-align: middle">
                          <div
                            style="
                              font-family: 'Quicksand', sans-serif;
                              font-weight: 500;
                              font-size: 12px;
                            "
                          >
                            Reserved
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="row justify-content-center mt-3" id="d5">
                <div class="col-md-12" id="tickets">
                  <div class="py-5">
                    <div class="text-center">
                      <h3
                        class="w-auto d-inline-block mb-1"
                        style="
                          font-family: 'nunito', sans-serif;
                          font-weight: 700;
                          font-size: 2.8em;
                          color: black;
                        "
                      >
                        Ticket Option
                      </h3>
                    </div>
                    <div
                      class="d-flex flex-row flex-wrap justify-content-center"
                    >
                      <div
                        class="card mx-4 mt-4"
                        style="
                          max-width: 400px !important;
                          border-radius: 10px;
                          overflow: hidden;
                          box-shadow: 4px 4px #293d58;
                          border-color: #293d58;
                          color: black;
                        "
                        v-bind:key="objk"
                        v-for="(obj, objk) in individualTick(TicketsResult)"
                      >
                        <div
                          class="card-header MYLE-green white"
                          style="
                            background-color: #d8b146;
                            color: black;
                            font-family: 'Montserrat', sans-serif;
                            font-weight: 700;
                            font-size: 1em;
                            font-weight: bold;
                          "
                        >
                          {{ obj.ticketName }}
                        </div>
                        <div
                          class="card-body"
                          style="
                            display: flex;
                            flex-direction: column;
                            background: #ffffff;
                          "
                        >
                          <p
                            v-bind:key="w"
                            v-for="(msgs, w) in (tkList[obj.ticketCode] || [])
                              .messages"
                          >
                            - {{ msgs }}
                          </p>
                          <div class="text-center" style="margin-top: auto">
                            <p>
                              <b class="font-weight-bold">💵 Total Price: </b
                              >${{ obj.price.toFixed(2) }}
                            </p>
                            <p
                              v-bind:key="linek"
                              v-for="(line, linek) in obj.description"
                            >
                              {{ line }}
                            </p>
                            <button
                              :disabled="
                                !obj.availableTickets ||
                                obj.status == 'EXPIRED' ||
                                obj.status == 'COMING_SOON'
                              "
                              v-bind:class="{
                                'bg-disable':
                                  obj.status == 'EXPIRED' ||
                                  !obj.availableTickets ||
                                  obj.status == 'COMING_SOON',
                              }"
                              class="btn solid-btn-100BMODSouth shimmer"
                              @click="openmodel(obj)"
                              style="
                                pointer-events: all;
                                cursor: pointer;
                                font-family: 'Montserrat', sans-serif;
                                font-weight: 700;
                                font-size: 1em;
                                font-weight: bold;
                              "
                            >
                              {{
                                obj.status == "EXPIRED" || !obj.availableTickets
                                  ? "Sales Ended"
                                  : obj.status == "COMING_SOON"
                                  ? "Coming Soon"
                                  : "Reserve Now"
                              }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Tickets Section starts -->
              <div class="row justify-content-center">
                <div class="col-md-12" id="sponsorship">
                  <div class="py-5">
                    <div class="text-center">
                      <h3
                        id="d6"
                        class="w-auto d-inline-block mb-2"
                        style="
                          font-family: 'nunito', sans-serif;
                          font-weight: 700;
                          font-size: 2.8em;
                          color: black;
                        "
                      >
                        Sponsorship
                      </h3>
                    </div>
                    <div
                      id="d11"
                      class="d-flex flex-row flex-wrap justify-content-center"
                    >
                      <div
                        class="card mx-4 mt-4"
                        style="
                          max-width: 400px !important;
                          border-radius: 10px;
                          overflow: hidden;
                          box-shadow: 4px 4px #293d58;
                          border-color: #293d58;
                          color: black;
                        "
                        v-bind:key="objk"
                        v-for="(obj, objk) in groupTickets(TicketsResult)"
                      >
                        <div
                          class="card-header MYLE-green white"
                          style="
                            background-color: #d8b146;
                            color: black;
                            font-family: 'Montserrat', sans-serif;
                            font-weight: 700;
                            font-size: 1em;
                            font-weight: bold;
                          "
                        >
                          {{ obj.ticketName }}
                        </div>
                        <div
                          class="card-body"
                          style="
                            display: flex;
                            flex-direction: column;
                            background: #ffffff;
                          "
                        >
                          <p
                            v-bind:key="w"
                            v-for="(msgs, w) in tkList[obj.ticketCode].messages"
                          >
                            - {{ msgs }}
                          </p>
                          <div class="text-center" style="margin-top: auto">
                            <p>
                              <b class="font-weight-bold">💵 Total Price: </b
                              >${{ obj.price.toFixed(2) }}
                            </p>
                            <p
                              v-bind:key="linek"
                              v-for="(line, linek) in obj.description"
                            >
                              {{ line }}
                            </p>
                            <button
                              :disabled="
                                !obj.availableTickets ||
                                obj.status == 'EXPIRED' ||
                                obj.status == 'COMING_SOON'
                              "
                              v-bind:class="{
                                'bg-disable':
                                  obj.status == 'EXPIRED' ||
                                  !obj.availableTickets ||
                                  obj.status == 'COMING_SOON',
                              }"
                              class="btn solid-btn-100BMODSouth shimmer"
                              @click="openmodel(obj)"
                              style="
                                pointer-events: all;
                                cursor: pointer;
                                font-family: 'Montserrat', sans-serif;
                                font-weight: 700;
                                font-size: 1em;
                                font-weight: bold;
                              "
                            >
                              {{
                                obj.status == "EXPIRED" || !obj.availableTickets
                                  ? "Sold Out"
                                  : obj.status == "COMING_SOON"
                                  ? "Coming Soon"
                                  : "Reserve Now"
                              }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="section-heading mt-0">
                <div class="text-center">
                  <a
                    href="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/xx.pdf"
                    class="btn solid-btn-100BMODSouth text-center shimmer"
                    target="_blank"
                    style="
                      pointer-events: all;
                      cursor: pointer;
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 700;
                      font-size: 1em;
                      font-weight: bold;
                    "
                    rel="noopener noreferrer"
                    >Sponsorship Deck
                  </a>
                </div>
              </div>
              <br /><br /><br /> -->

              <div class="container">
                <video-embed
                  css="embed-responsive-16by9"
                  src="https://www.youtube.com/watch?v=XAsWBG9BFI0&ab_channel=BroderickTalley"
                ></video-embed>
              </div>

              <br /><br /><br />

              <div class="container">
                <vue-flux
                  :options="vfOptions"
                  :images="vfEventImgs"
                  :transitions="vfTransitions"
                  ref="slider"
                  style="z-index: 1 !important"
                >
                  <template v-slot:preloader>
                    <flux-preloader />
                  </template>
                  <template v-slot:controls>
                    <flux-controls />
                  </template>
                </vue-flux>

                <!-- <div class="">
                      <img
                        src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/SWA_jazz_img.png"
                        class="w-100 img-fluid"
                        style="border-radius: 3%"
                      />
                    </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div
      class="row w-100 m-0 promo-section MYLE-lite-green"
      style="background: #d8b146"
    >
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center" style="color: white">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="section-heading mb-0 p-3 mt-5" style="background: #faf9f6">
      <div class="text-center">
        <a
          href="#tickets"
          class="btn solid-btn-100BMODSouth ml-3 mr-3 mb-1 text-center"
          target="_self"
          style="
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            font-size: 1em;
            font-weight: bold;
          "
          rel="noopener noreferrer"
          >Buy Tickets</a
        >

        <a
          href="https://myle.com/100-black-men-of-south-metro-35th-annual-celebration-of-excellence-scholarship-gala-raffle"
          class="btn solid-btn-100BMODSouth ml-3 mr-3 mb-1 text-center"
          target="_self"
          style="
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            font-size: 1em;
            font-weight: bold;
          "
          rel="noopener noreferrer"
          >Buy Raffle Tickets</a
        >

        <a
          href="https://myle.com/100-black-men-of-south-metro-35th-annual-celebration-of-excellence-scholarship-gala-donation"
          class="btn solid-btn-100BMODSouth mb-1 ml-3 mr-3 text-center"
          target="_self"
          style="
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            font-size: 1em;
            font-weight: bold;
          "
          >Donate</a
        >

        <a
          :disabled="true"
          v-bind:class="{
            'bg-disable': true,
          }"
          href="#"
          class="btn solid-btn-100BMODSouth mb-1 ml-3 mr-3 text-center"
          target="_self"
          style="
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            font-size: 1em;
            font-weight: bold;
          "
          >Silent Auction</a
        >

        <a
          href="#sponsorship"
          class="btn solid-btn-100BMODSouth mb-1 ml-3 mr-3 text-center"
          target="_self"
          style="
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            font-size: 1em;
            font-weight: bold;
          "
          >Sponsorship</a
        >

        <!-- <a
            href="https://myle.com/2024-scholarship-gala-weekend"
            class="btn solid-btn-100blackmen-inverted mb-1 ml-3 mr-3 text-center"
            target="_self"
            >Back To Main Page</a
          > -->

        <!-- <a
            href="https://myle.com/scholarship-gala-benefitting-the-leadership-academy-silent-auction"
            class="btn solid-btn-100blackmen-inverted ml-3 mr-3 mb-1 text-center"
            target="_blank"
            rel="noopener noreferrer"
            >Silent Auction</a
          > -->
      </div>
    </div>

    <div class="row w-100 m-0 promo-section mt-5">
      <div class="col-lg-12 col-md-12">
        <div class="">
          <div class="section-heading mb-5">
            <h2
              class="text-center"
              style="
                font-family: 'Quicksand', sans-serif;
                font-weight: 500;
                font-size: 15px;
              "
            >
              Powered by <a target="_blank" href="https://myle.com/">MYLE</a>
            </h2>
          </div>
        </div>
      </div>
    </div>

    <!-- <site-footer :show-subscribe="true" /> -->
  </div>
</template>

<script>
// Component Imports
// import NavBar from "../../../views/commons/NavBar";
// import SiteFooter from "../../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
import confirmationPopup from "../../../components/confirmationPopup";
import { VueFlux, FluxPreloader, FluxControls } from "vue-flux";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      userContactPhone: "",
      bname: "",
      orgName: "",
      ageRangesList: ["21-29", "30-39", "40-49", "50+"],
      ageRange: "21-29",
      selectionList: [],
      dict: [],
      availSeating: "",
      required_power_needs: "No",
      selectedSizes: "Extra Small - XS",
      sizes: [
        "Extra Small - XS",
        "Small - S",
        "Medium - M",
        "Large - L",
        "Extra Large - XL",
        "2 Extra Large - 2XL",
        "3 Extra Large - 3XL",
        "4 Extra Large - 4XL",
        "5 Extra Large - 5XL",
      ],
      individualsData: ["tk_xc1WPgVRZof4PEm"],

      groupData: [
        "tk_g79YpVOK1OokKzJ",
        "tk_QZ7Z2SFG4430fvx",
        "tk_iXVLjxpGPFJnVu7",
        "tk_qWRDR6Q0Wyz4DwE",
      ],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        tk_xc1WPgVRZof4PEm: {
          messages: ["Ticket Price: $150", "Processing fee of $11.7"],
        },
        tk_g79YpVOK1OokKzJ: {
          messages: [
            "2 Tables of Ten to our Signature Fundraiser",
            "Recognition on chapter website and Social Media Pages",
            "Logo Placement in Event Marketing Materials",
            "Sponsorship Cost: $20,000.00",
          ],
        },
        tk_QZ7Z2SFG4430fvx: {
          messages: [
            "10 Tickets to our Signature Fundraiser",
            "Logo Placement on Chapter Websit",
            "Logo Placement in Event Marketing Materials",
            "Sponsorship Cost: $10,000.00",
          ],
        },
        tk_iXVLjxpGPFJnVu7: {
          messages: [
            "4 Tickets to our Signature Fundraiser",
            "Logo Placement in Event Marketing Materials",
            "Sponsorship Cost: $5,000.00",
          ],
        },
        tk_qWRDR6Q0Wyz4DwE: {
          messages: [
            "2 Tickets",
            "Logo placement on marketing materials for chapter events",
            "Sponsorship Price: $1500",
          ],
        },
      },

      priceIds: {
        tk_xc1WPgVRZof4PEm: "price_1QpBhyD0vTZ4QB9cKzGl3C3Y",
        tk_g79YpVOK1OokKzJ: "price_1QpCWKD0vTZ4QB9cMzTBnfvM",
        tk_QZ7Z2SFG4430fvx: "price_1Qr895D0vTZ4QB9cYd2Xxk3U",
        tk_iXVLjxpGPFJnVu7: "price_1QpCWLD0vTZ4QB9c65ti471U",
        tk_qWRDR6Q0Wyz4DwE: "price_1QpCWLD0vTZ4QB9cx5C3rLLO",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      hearAbout: "",
      tickcount: 1,
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_slider_show_BlackMenofSouthMetro35thAnnualMain_1.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_slider_show_BlackMenofSouthMetro35thAnnualMain_2.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_slider_show_BlackMenofSouthMetro35thAnnualMain_3.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_slider_show_BlackMenofSouthMetro35thAnnualMain_4.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_slider_show_BlackMenofSouthMetro35thAnnualMain_5.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_slider_show_BlackMenofSouthMetro35thAnnualMain_6.png",
      ],
    };
  },
  components: {
    // NavBar,
    // SiteFooter,
    VueElementLoading,
    VueFlux,
    FluxPreloader,
    FluxControls,
    StripeCheckout,
    confirmationPopup,
  },

  methods: {
    datad(listdata) {
      const seat = {
        1: 1200,
        2: 600,
        3: 400,
        4: 300,
        5: 240,
        6: 200,
        7: 171.4285714,
        8: 150,
        9: 133.33333,
        10: 120,
        11: 109.1,
      };
      const texts = [
        [1, 13, 25, 37, 49],
        [2, 14, 26, 38, 50],
        [3, 15, 27, 39, 51],
        [4, 16, 28, 40, 52],
        [5, 17, 29, 41, 53],
        [6, 18, 30, 42, 54],
        [],
        [7, 19, 31, 43, 55],
        [8, 20, 32, 44, 56],
        [9, 21, 33, 45, 57],
        [10, 22, 34, 46, 58],
        [11, 23, 35, 47, 59],
        [12, 24, 36, 48, 60],
      ];
      const ydistances = [50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50];
      const nodes = [];
      for (let y = 1; y <= texts.length; y++) {
        for (let i = 1; i <= texts[y - 1].length; i++) {
          if (texts[y - 1][i - 1]) {
            nodes.push({
              x: 70 * y,
              y: 70 * i + ydistances[y - 1],
              val: seat[10],
              distance: y * 10,
              ydistance: i * 10,
              selected: false,
              text: texts[y - 1][i - 1],
              availableSeats: [],
            });
          }
        }
      }

      let recs = [];
      this.dict = {};
      listdata.forEach((r) => {
        if (r.ticketCode == "tk_xc1WPgVRZof4PEm") {
          recs = [...recs, ...r.availableSeating];
        }
      });

      recs.forEach((r) => {
        const tableRecord = r.table.split("Table ")[1];
        const seatRecord = r.seat.split("Seat ")[1];

        if (!this.dict[tableRecord]) {
          this.dict[tableRecord] = [];
        }

        this.dict[tableRecord].push(Number(seatRecord - 1));
      });
      return nodes;
    },
    drawseatsd(listdata) {
      const vm = this;
      const svg = window.d3
        .select("div#dx")
        .append("svg")
        .attr("width", "1100px")
        .attr("height", "550px");
      const innerCircleRadius = 15;
      const outerCircleRadius = 15;
      const outerCircleRadius1 = 15;
      this.datad(listdata).forEach((v) => {
        const originX = v.x + v.distance;
        const originY = v.y + v.ydistance;
        svg.append("circle").attr({
          cx: originX,
          cy: originY,
          r: innerCircleRadius,
          fill: v.selected ? "yellow" : "none",
          stroke: "black",
        });

        svg
          .append("text")
          .attr({
            x:
              originX -
              (Number(v.text) > 9 ? (Number(v.text) > 99 ? 12 : 9) : 3),
            y:
              originY +
              (Number(v.text) > 9 ? (Number(v.text) > 99 ? 5 : 5) : 5),
            "font-size": 15,
          })
          .text(v.text);

        for (let i = 0; i < 1200; i += v.val) {
          const chairOriginX1 =
            originX - 3 + (outerCircleRadius1 + 16) * Math.sin(i);
          const chairOriginY1 =
            originY + 2 - (outerCircleRadius1 + 16) * Math.cos(i);
          const chairOriginX = originX + (outerCircleRadius + 5) * Math.sin(i);
          const chairOriginY = originY - (outerCircleRadius + 5) * Math.cos(i);

          svg.append("circle").attr({
            cx: i == 1200 ? Math.ceil(chairOriginX + 1) : chairOriginX,
            cy: chairOriginY,
            r: 4,
            fill:
              vm.dict[v.text] &&
              vm.dict[v.text].includes(i > 0 ? Math.ceil(i / v.val) : 0)
                ? "#dab785"
                : "lightgrey",
          });

          svg
            .append("text")
            .attr({
              x: chairOriginX1,
              y: chairOriginY1,
              stroke: "black",
              "font-size": 10,
            })
            .text(() => {
              return i > 0 ? Math.ceil(i / v.val) + 1 : 1;
            });
        }
      });

      /// rects
      svg
        .append("rect")
        .attr("width", 550)
        .attr("height", 40)
        .attr("x", 290)
        .attr("fill", "none")
        .attr("stroke", "black");

      svg
        .append("text")
        .attr("x", 536.5)
        .attr("y", 25)
        .text("Stage")
        .attr("fill", "none")
        .attr("stroke", "black");
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    listtopush(rr) {
      this.selectionList = [...rr];
    },
    uniq(list) {
      if (list && list.length > 0) {
        const uniqValues = {};
        list.forEach((v) => {
          uniqValues[v.table] = true;
        });

        return Object.keys(uniqValues);
      }

      return [];
    },
    uniqV(list, table) {
      console.log(list, table);
      if (list && list.length > 0) {
        const uniqValues = [];
        list.forEach((v) => {
          if (v.table === table) {
            uniqValues.push(uniqValues.length + 1);
          }
        });

        return uniqValues;
      }

      return [];
    },
    datad1(listdata) {
      const seat = {
        1: 1200,
        2: 600,
        3: 400,
        4: 300,
        5: 240,
        6: 200,
        7: 171.4285714,
        8: 150,
        9: 133.33333,
        10: 120,
        11: 109.1,
      };
      const texts = [
        [1, 6, 7, 11, 12, 17, 18],
        [2, 5, 8, 10, 13, 16, 19],
        [3, 4, 9, 14, 15, 20],
      ];
      const distances = [0, 0, 20];
      const nodes = [];
      for (let y = 1; y <= 3; y++) {
        for (let i = 1; i <= 7; i++) {
          if (texts[y - 1][i - 1]) {
            nodes.push({
              x: 70 * i + distances[y - 1],
              y: 70 * y,
              val: seat[8],
              distance: i * 10,
              ydistance: y * 10,
              selected: false,
              text: texts[y - 1][i - 1],
              availableSeats: [],
            });
          }
        }
      }

      let recs = [];
      this.dict = {};
      listdata.forEach((r) => {
        recs = [...recs, ...r.availableSeating];
      });

      recs.forEach((r) => {
        const tableRecord = r.table.split("Table ")[1];
        const seatRecord = r.seat.split("Seat ")[1];

        if (!this.dict[tableRecord]) {
          this.dict[tableRecord] = [];
        }

        this.dict[tableRecord].push(Number(seatRecord - 1));
      });
      return nodes;
    },
    drawseatsd1(listdata) {
      const vm = this;
      const svg = window.d3
        .select("div#dx")
        .append("svg")
        .attr("width", "1000px")
        .attr("height", "300px");
      const innerCircleRadius = 15;
      const outerCircleRadius = 15;
      const outerCircleRadius1 = 15;
      this.datad1(listdata).forEach((v) => {
        const originX = v.x + v.distance;
        const originY = v.y + v.ydistance;
        svg.append("circle").attr({
          cx: originX,
          cy: originY,
          r: innerCircleRadius,
          fill: v.selected ? "yellow" : "none",
          stroke: "black",
        });

        svg
          .append("text")
          .attr({
            x:
              originX -
              (Number(v.text) > 9 ? (Number(v.text) > 99 ? 12 : 9) : 3),
            y:
              originY +
              (Number(v.text) > 9 ? (Number(v.text) > 99 ? 5 : 5) : 5),
            "font-size": 15,
          })
          .text(v.text);
        console.log("--------");
        for (let i = 0; i < 1200; i += v.val) {
          const chairOriginX1 =
            originX - 3 + (outerCircleRadius1 + 16) * Math.sin(i);
          const chairOriginY1 =
            originY + 2 - (outerCircleRadius1 + 16) * Math.cos(i);
          const chairOriginX = originX + (outerCircleRadius + 5) * Math.sin(i);
          const chairOriginY = originY - (outerCircleRadius + 5) * Math.cos(i);

          svg.append("circle").attr({
            cx: i == 1200 ? Math.ceil(chairOriginX + 1) : chairOriginX,
            cy: chairOriginY,
            r: 4,
            fill:
              vm.dict[v.text] &&
              vm.dict[v.text].includes(i > 0 ? Math.ceil(i / v.val) : 0)
                ? v.text == "1" || v.text == "9"
                  ? "#0ea293"
                  : "#a19140"
                : "lightgrey",
          });

          svg
            .append("text")
            .attr({
              x: chairOriginX1,
              y: chairOriginY1,
              stroke: "black",
              "font-size": 10,
            })
            .text(() => {
              return i > 0 ? Math.ceil(i / v.val) + 1 : 1;
            });
        }
      });

      /// rects
      svg
        .append("rect")
        .attr("width", 50)
        .attr("height", 40)
        .attr("x", 300)
        .attr("fill", "none")
        .attr("stroke", "black");
      svg
        .append("text")
        .attr("x", 306.5)
        .attr("y", 25)
        .text("Stage")
        .attr("fill", "none")
        .attr("stroke", "black");

      svg
        .append("rect")
        .attr("width", 40)
        .attr("height", 40)
        .attr("y", 100)
        .attr("fill", "none")
        .attr("stroke", "black");
      svg
        .append("text")
        .attr("y", 125)
        .attr("x", 2.5)
        .text("Band")
        .attr("fill", "none")
        .attr("stroke", "black");
    },
    individualTick(list) {
      return list.filter((listRecord) =>
        this.individualsData.includes(listRecord.ticketCode)
      );
    },
    groupTickets(list) {
      return list.filter((listRecord) =>
        this.groupData.includes(listRecord.ticketCode)
      );
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (mod) {
      let idx = 0;
      this.TicketsResult.forEach((i, i1) => {
        if (i.ticketCode == mod.ticketCode) {
          idx = i1;
        }
      });
      this.availSeating = "";
      this.selectionList = [];
      this.redeemData = null;
      this.redeemPriceId = "";
      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      if (
        this.TicketsResult[idx].availableSeating &&
        this.TicketsResult[idx].availableSeating[0]
      ) {
        this.availSeating = this.TicketsResult[idx].availableSeating[0].table;
      } else {
        this.availSeating = "";
      }

      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
      };

      payloadToPush.extraInfo = {
        phone: this.userContactPhone,
        businessName: this.bname,
        orgName: this.orgName,
      };

      //   if (vm.groupData.includes(vm.TicketsResult[vm.selected].ticketCode)) {
      //     payloadToPush.extraInfo["required_power_needs"] =
      //       vm.required_power_needs;
      //   }

      const restdata = [];

      if (vm.availSeating.length > 0) {
        const ress = this.TicketsResult[vm.selected].availableSeating.filter(
          (e) => e.table === vm.availSeating
        );
        console.log(vm.availSeating, ress);
        for (let r = 0; r < Number(this.tickcount); r++) {
          restdata.push(ress[r].alias);
        }
        payloadToPush.additionalInfo = {
          type: "Seating",
          value: restdata,
        };
      }

      const req =
        "https://myle-19701.uc.r.appspot.com/api/" +
        ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
          !this.redeemData) ||
        (this.redeemData && this.redeemData.price !== 0)
          ? "requested_tickets"
          : "requested_free_tickets");
      axios
        .post(req, payloadToPush)
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (vm.redeemData && vm.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.ageRange = "21-29";
            vm.fullname = "";
            vm.email = "";
            vm.bname = "";
            vm.orgName = "";
            vm.userContactPhone = "";
            vm.isSubmitted = false;
          }

          setTimeout(() => {
            vm.isSubmitted = false;
          }, 60000);
          vm.reserveFormAccept = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      const payloadToPush = {
        eventId: "huy9b8RDosmuVO",
      };
      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsoredEventTicketsWithSeating",
          payloadToPush
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          const objtmp = {
            tk_xc1WPgVRZof4PEm: null,
            tk_g79YpVOK1OokKzJ: null,
            tk_QZ7Z2SFG4430fvx: null,
            tk_iXVLjxpGPFJnVu7: null,
            tk_qWRDR6Q0Wyz4DwE: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr;
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;

          vm.drawseatsd(vm.eventTicketResult);
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  mounted() {
    console.log("====================================");
    const isScrolledIntoView = (el) => {
      var top = el.offsetTop;
      var left = el.offsetLeft;
      var width = el.offsetWidth;
      var height = el.offsetHeight;

      while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
      }

      return (
        top < window.pageYOffset + window.innerHeight &&
        left < window.pageXOffset + window.innerWidth &&
        top + height > window.pageYOffset &&
        left + width > window.pageXOffset
      );
    };

    window.addEventListener("scroll", function () {
      let container = document.querySelector("#d1");
      if (isScrolledIntoView(container)) {
        container.classList.add("inView");
      }
      container = document.querySelector("#d2");
      if (isScrolledIntoView(container)) {
        container.classList.add("inView");
      }
      container = document.querySelector("#d3");
      if (isScrolledIntoView(container)) {
        container.classList.add("inView");
      }
      container = document.querySelector("#d4");
      if (isScrolledIntoView(container)) {
        container.classList.add("inView");
      }
      container = document.querySelector("#d5");
      if (isScrolledIntoView(container)) {
        container.classList.add("inView");
      }

      container = document.querySelector("#d6");
      if (isScrolledIntoView(container)) {
        container.classList.add("inView");
      }
      container = document.querySelector("#d7");
      if (isScrolledIntoView(container)) {
        container.classList.add("inView");
      }
      container = document.querySelector("#d8");
      if (isScrolledIntoView(container)) {
        container.classList.add("inView");
      }
      container = document.querySelector("#d9");
      if (isScrolledIntoView(container)) {
        container.classList.add("inView");
      }
      container = document.querySelector("#d11");
      if (isScrolledIntoView(container)) {
        container.classList.add("inView");
      }

      container = document.querySelector("#i1");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromside");
      }

      container = document.querySelector("#i2");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromsideo");
      }

      container = document.querySelector("#i3");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromside");
      }

      container = document.querySelector("#i4");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromsideo");
      }

      container = document.querySelector("#i5");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromside");
      }

      container = document.querySelector("#i6");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromsideo");
      }

      container = document.querySelector("#i7");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromside");
      }

      container = document.querySelector("#i8");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromsideo");
      }

      container = document.querySelector("#i9");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromside");
      }

      container = document.querySelector("#i10");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromsideo");
      }

      container = document.querySelector("#i11");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromside");
      }

      container = document.querySelector("#i12");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromsideo");
      }

      container = document.querySelector("#i13");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromside");
      }

      container = document.querySelector("#i14");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromsideo");
      }

      container = document.querySelector("#i15");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromside");
      }

      container = document.querySelector("#i16");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromsideo");
      }

      container = document.querySelector("#i17");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromside");
      }

      container = document.querySelector("#i18");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromsideo");
      }

      container = document.querySelector("#i19");
      if (isScrolledIntoView(container)) {
        container.classList.add("fromside");
      }

      // container = document.querySelector("#imagesection1");
      // if (isScrolledIntoView(container)) {
      //   container.classList.add("fromsideo");
      // }
      // container = document.querySelector("#imagesectio2");
      // if (isScrolledIntoView(container)) {
      //   container.classList.add("fromside");
      // }
    });

    this.fetchAllTickets();
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
    invaliduphone() {
      if (this.userContactPhone.length <= 0) {
        return false;
      }
      return (
        (this.userContactPhone || "").match(/\d/g) &&
        (this.userContactPhone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title:
      "MYLE - 100 Black Men of South Metro Atlanta 35th Annual Celebration of Excellence Scholarship Gala",
    meta: [
      {
        name: "description",
        content:
          "MYLE - 100 Black Men of South Metro Atlanta 35th Annual Celebration of Excellence Scholarship Gala",
      },

      {
        name: "keywords",
        content:
          "MYLE - 100 Black Men of South Metro Atlanta 35th Annual Celebration of Excellence Scholarship Gala",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content:
          "https://myle.com/100-black-men-of-south-metro-35th-annual-celebration-of-excellence-scholarship-gala",
      },
    ],
  },
};
</script>

<style scoped>
html {
  scroll-behavior: smooth;
}

.velmld-parent {
  background: #ffffff !important;
}

#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.cgreen {
  background: #0ea293;
  border: 1px solid #0ea293 !important;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

.bolded {
  font-weight: bold;
  font-size: 1.2em;
}

@media (max-width: 768px) {
  #redeem {
    text-align: center;
  }
}

@media (max-width: 622px) {
  #dx {
    overflow-x: scroll;
  }
}
@media (min-width: 622.1px) {
  #dx {
    overflow-x: scroll;
  }
}

.shimmer {
  display: inline-block;
  mask: linear-gradient(-60deg, #000 30%, rgba(179, 255, 0, 0.333), #000 70%)
    right/350% 100%;
  animation: shimmer 2.5s infinite;
}

@keyframes shimmer {
  100% {
    mask-position: left;
  }
}

.shimmer {
  display: inline-block;
  mask: linear-gradient(-60deg, #000 30%, rgba(179, 255, 0, 0.333), #000 70%)
    right/350% 100%;
  animation: shimmer 2.5s infinite;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.fromside {
  animation-duration: 1s; /* the duration of the animation */
  animation-timing-function: ease-out; /* how the animation will behave */
  animation-delay: 0s; /* how long to delay the animation from starting */
  animation-iteration-count: 1; /* how many times the animation will play */
  animation-name: slideInFromLeft; /* the name of the animation we defined above */
}

@keyframes slideInFromLefto {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.fromsideo {
  animation-duration: 1s; /* the duration of the animation */
  animation-timing-function: ease-out; /* how the animation will behave */
  animation-delay: 0s; /* how long to delay the animation from starting */
  animation-iteration-count: 1; /* how many times the animation will play */
  animation-name: slideInFromLefto; /* the name of the animation we defined above */
}

@keyframes shimmer {
  100% {
    mask-position: left;
  }
}

@keyframes example {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.1;
  }
  20% {
    opacity: 0.2;
  }
  30% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.5;
  }
  70% {
    opacity: 0.7;
  }
  80% {
    opacity: 0.8;
  }
  90% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

.inView {
  animation-name: example;
  animation-duration: 2.5s;
}
</style>
