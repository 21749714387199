<template>
  <div>
    <nu-modal ref="termsModal" title="">
      <div class="container-fluid">
        <div class="row">
          <!-- Ticket Cart -->

          <div
            class="row align-items-center justify-content-between"
            style="margin: auto; width: 100% !important"
          >
            <div class="col-md-12">
              <div class="text-center">
                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'Source Sans 3';
                    font-size: 2.3em;
                    font-weight: 700;
                    color: #0f3d70;
                  "
                >
                  Terms & Conditions
                </span>
              </div>
            </div>
            <div class="col-lg-12 col-md-12">
              <div style="height: auto; overflow: auto">
                <p
                  class="mb-1 mt-3"
                  style="
                    font-family: 'Source Sans 3', sans-serif;
                    font-size: 1.1em;
                    color: black;
                    width: 100%;
                  "
                >
                  MUST BE 21 OR OLDER. Offer valid throug May 18th, 2025.
                  Participation in the Navy Federal Credit Union Military
                  Appreciation Month MLB Events (“Event”) is limited, and is on
                  a first-come, first-served basis. Limit of TWO (2) Event
                  tickets per cardholder. Payment must be completed with a valid
                  U.S. Navy Federal Credit Union Visa Card. A $20 donation to
                  United Through Reading amount is due at the time of booking to
                  confirm tickets. The package associated with the Event
                  includes food and beverage and/or other elements determined by
                  Event Parties (defined below) in their sole discretion (the
                  “Event Package”).

                  <br /><br />

                  Offer excludes parking, accommodations, and any other items
                  not specifically set forth in advertising materials. Event
                  Packages are not transferable and may not be resold or
                  redeemed for cash. Void where prohibited. Offer cannot be
                  combined with any other offer or discount. Any applicable
                  taxes must be paid by each redeemer of the offer.

                  <br /><br />

                  References to “you” or “your” includes redeemer of the offer
                  and any guests participating in/attending the Event. By
                  participating in/attending the Event, you accept these terms
                  and conditions (“Terms and Conditions”).

                  <br /><br />

                  <span class="bolded">
                    Event Cancellation; Delays; Event Package Changes.</span
                  >
                  Visa reserves the right to modify, reschedule, or cancel the
                  Event, at any time and shall use good faith efforts to notify
                  participants. Event schedules and staffing are subject to
                  change. Circumstances beyond the control of Visa Inc., Visa
                  U.S.A. Inc., Visa International Service Association, Vega
                  Group, LLC, Navy Federal Credit Union, or any of their client
                  financial institutions, prize suppliers, advertising,
                  promotion and marketing agencies, Make Your Life Entertaining,
                  Inc., or each of their respective parents, subsidiaries,
                  successors, affiliates, and related companies (collectively,
                  “Event Parties”) may cause delays in delivery of any products,
                  content or materials to be delivered to you for the Event.
                  Further, the Event Parties make no guarantees as to the
                  content of any Event Package and may change any Event Package
                  in their discretion. <br /><br />

                  <span class="bolded">No Warranties.</span> EVENT PARTIES MAKE
                  NO WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
                  INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
                  MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
                  NON-INFRINGEMENT REGARDING THE EVENT OR OFFER. ATTENDANCE OF
                  EVENT IS AT YOUR OWN RISK.

                  <br /><br />

                  <span class="bolded">
                    Limitation of Liability & Release.
                  </span>
                  In consideration of your access to and participation in the
                  Event, you agree that in no event will any of the Event
                  Parties be liable under any theory of tort, contract, strict
                  liability or other legal or equitable theory for any lost
                  profits, lost data, lost opportunities, costs of cover,
                  exemplary, punitive, personal injury/wrongful death,
                  contraction of illness or disease, special, incidental,
                  indirect or other consequential damages, and you release the
                  Event Parties from all such claims. Further, to the fullest
                  extent permitted by law, in no event will the Event Parties be
                  liable for any damages resulting from your access to or
                  participation in the Event, or acceptance, possession, use,
                  misuse or nonuse of the offer (including any travel or
                  travel-related activity thereto), each of which, to the
                  fullest extent permitted by law, is hereby excluded by
                  agreement of the parties regardless of whether or not either
                  party has been advised of the possibility of such damages.
                  Event Parties are not responsible for any lost or missing
                  packages. California Residents: If you are a California
                  resident, you hereby waive California Civil Code Section 1542
                  in connection with the foregoing, which states: "a general
                  release does not extend to claims which the creditor does not
                  know or suspect to exist in his or her favor at the time of
                  executing the release, which if known by him or her must have
                  materially affected his or her settlement with the debtor."

                  <br /><br />

                  <span class="bolded">
                    Infectious Disease Acknowledgement.</span
                  >
                  You acknowledge that by participating in or traveling to the
                  Event you may come into contact with other individuals and may
                  be at risk of contracting infectious diseases (including
                  without limitation COVID-19). You assume all risks of
                  contracting any such infectious diseases in connection with
                  the Event. You agree to abide by any and all safety practices,
                  guidelines and protocols provided by Event Parties, and to
                  provide proof of vaccination (if applicable) and/or execute
                  any additional documentation which may be requested and/or
                  required by such parties prior to the Event.

                  <br /><br />

                  <span class="bolded"> Prohibited Activities. </span>The Event
                  is provided for your personal and non-commercial use. Any
                  other use or activity in connection with the Event or
                  materials provided or made available by the Event Parties
                  requires the prior written consent of the Event Parties. The
                  Event Parties will solely make all determinations in
                  connection with the Event, and your access to the Event or any
                  content or materials related thereto may suspend or terminated
                  for any reason at any time with or without notice.

                  <br /><br />

                  <span class="bolded"> Event Photography & Recording. </span
                  >The Event may be recorded or otherwise documented, and
                  participants may be included in the recording or other
                  materials (as applicable). By participating in the Event, you
                  hereby grant to the Event Parties the irrevocable and absolute
                  right and permission to use, in whole or in part, your name,
                  image, photograph, voice, statements (actual or paraphrased),
                  performance and/or any other likeness or indicia identifying
                  you (your “Likeness”) as recorded and/or embodied in one or
                  more recordings, videos, still images and written materials
                  (“Recordings”), including without limitation in connection
                  with internet and digital materials and in press, public
                  relations and publicity materials, all for the purpose of
                  advertising, publicity and marketing for Event Parties and
                  their products and services and/or any other lawful purpose,
                  worldwide, in any and all media now known or hereinafter
                  invented. In addition, at any time Event Parties may use the
                  Recordings and any materials produced therefrom anywhere in
                  the world for industry trade events, sales meetings and for
                  intra-company, research, award, publicity, file, and reference
                  purposes. Further, you acknowledge that other Event attendees
                  may be able to access Recordings that feature your Likeness
                  (including, without limitation, access to an online photo
                  gallery). By attending the Event, you hereby consent to your
                  Likeness being viewable and downloadable by other Event
                  attendees, agree that the Event Parties shall not be
                  responsible for any use by any other Event attendees of any
                  Recordings that may contain your Likeness, and hereby release
                  the Event Parties from any claims or damages in connection
                  with any such use by any other Event attendee. You also agree
                  that you will not use any Recordings, regardless of whether
                  such Recording contains your Likeness, for any unlawful or
                  commercial purpose.

                  <br /><br />

                  Major League Baseball. By participating in the Event, you also
                  agree to comply with the MLB Terms and Conditions available
                  at:
                  <a
                    href="https://www.mlb.com/nationals/tickets/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                    style="color: #0068ef"
                  >
                    https://www.mlb.com/nationals/tickets/terms-and-conditions</a
                  >

                  <br /><br />

                  <span class="bolded"> Questions:</span> Should you have any
                  questions regarding these Terms and Conditions or the Event,
                  please email
                  <a
                    style="color: #0068ef"
                    href="mailto:NavyFedVisaPadres@160over90.com"
                  >
                    NavyFedVisaPadres@vegagroup.com</a
                  >.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nu-modal>

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <!-- <div class="row text-center">
          <div class="col-md-12" id="banner">
            <img
              class="align-middle"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/Tennessee-Header.jpeg"
              style="
                width: 100%;
                max-width: 100%;
                height: auto;
                margin-top: 4.2rem;
              "
            />
          </div>
        </div> -->
      <!-- banner section ends -->
      <div class="row w-100 m-0 promo-section mt-5" style="background: #0f3d70">
        <div class="col-lg-12 col-md-12 mt-5">
          <div class="mt-2">
            <div class="section-heading mb-4">
              <h1
                class="text-center text-white"
                style="
                  font-family: 'Source Sans 3';
                  font-weight: 700;
                  font-size: 2.5em;
                  color: white;
                "
              >
                Frequently Asked Questions
              </h1>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between mb-0 lessSideMargin"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <p
            class="lead-heading mb-2"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            1. Why am I getting this invitation?
          </p>

          <p
            class="lead-heading ml-4"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            o You are receiving this invitation to an exclusive MLB Hospitality
            Suites event in honor of Military Appreciation month as a gesture of
            appreciation for being a loyal Navy Federal Credit Union Visa
            Signature<sup>®</sup> Credit member.
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            2. How many registrations or tickets can I have?
          </p>

          <p
            class="lead-heading ml-4"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            o One (1) registration per Navy Federal Credit Union Visa
            Signature<sup>®</sup>
            Credit member for up to two (2) tickets.
            <br />
            o Tickets are tied to your Navy Federal Credit Union Visa
            Signature<sup>®</sup>
            Credit card and email address.
            <br />
            o Registrations are not transferrable.
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            3. Do I pay $20 for each ticket?
          </p>

          <p
            class="lead-heading ml-4"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            o No, the $20 charitable donation to United Through Reading is per
            registration (up to 2 tickets)
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            4. Will I receive an email confirmation?
          </p>

          <p
            class="lead-heading ml-4"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            o Yes, an email confirmation will be automatically sent to your
            registered email address immediately after you complete the
            registration process, providing instructions on how to set up your
            MLB.com account.
            <br />
            o You will receive an email on May 7th reminding you to set up an
            MLB MLB.com account and an additional email notifying you of the
            ticket delivery no later than May 12.
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            5. When will I get my tickets?
          </p>

          <p
            class="lead-heading ml-4"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            o No later than May 12, 2025, and upon completing your registration.
            <br />
            o Digital delivery will be through the MLB Ballpark App.
            <br />
            o You will receive an email from MLB.com once your ticket has been
            assigned. Make sure to check spam/junk folders.
            <br />
            o You must set up an MLB.com account and download the MLB Ballpark
            App.
            <br />
            o You must use the email you used to register to set up your MLB.com
            account and to log in to your MLB Ballpark App
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            6. What if I already have an MLB Ballpark App MLB.com account and
            used an email different from my Navy Federal Credit Union Visa
            Signature<sup>®</sup> account email?
          </p>

          <p
            class="lead-heading ml-4"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            o You must use the same event registration email on the MLB.com
            account and login credentials to the MLB Ballpark App.
            <br />
            o Please set up another MLB.com account using the email used to
            register.
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            7. How do I access my digital tickets?
          </p>

          <p
            class="lead-heading ml-4"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            o CREATE AN ACCOUNT:
            <br />
            <span class="ml-4"
              >▪︎ Set up an account on MLB.com
              <br />
              <span class="ml-5">
                • Use your same event registration email (Navy Federal Credit
                Union Visa Signature<sup>®</sup> account email) and create your
                own password
              </span>
              <br />
              <span class="ml-5">
                • You will use these credentials on the MLB Ballpark App
              </span>
            </span>
            <br />
            <span class="ml-4">
              ▪︎ VERIFY EMAIL: (only if creating a new account) MLB.com will
              send an email to verify your email address; click the link to
              verify your email address</span
            >
            <br /><br />
            o DOWNLOAD THE APP:
            <br />
            <span class="ml-4">
              ▪︎ Download the MLB Ballpark App on the App Store (iPhone) or
              Google Play Store (Android).
            </span>
            <br />
            <span class="ml-4">
              ▪︎ LOG IN to the MLB Ballpark App using the credentials from your
              MLB.com account with the Navy Federal Credit Union Visa
              Signature<sup>®</sup>
              account email and password you created
            </span>
            <br />
            <span class="ml-5">
              • After you’ve accessed the app, select the ‘Washington Nationals’
              as your Favorite team to make it easy to access all Nationals Park
              features.
              <br /> </span
            ><span class="ml-5">
              • You will receive an email from MLB.com notifying you that the
              tickets have been placed in your account (be sure to check your
              spam/junk folders).
              <br /> </span
            ><span class="ml-5">
              • Once assigned, your tickets will be under the ticket tab (on or
              before May 12)
              <br /> </span
            ><span class="ml-5">
              • At the ballpark, you will click on open tickets to show your
              ticket at the gate
            </span>
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            8. If I register and can no longer attend, can I give my spot to
            someone else?
          </p>

          <p
            class="lead-heading ml-4"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            o Tickets are limited. If you are no longer able to attend this
            event, please contact us no later than 48 hours prior to the event
            date at
            <a
              style="color: #0068ef"
              href="mailto:NavyFedVisaPadres@vegagroup.com"
            >
              NavyFedVisaPadres@vegagroup.com</a
            >
            to cancel your reservation. Your card will be refunded the full $20
            amount to the card provided.
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            9. If I am on the waitlist, how do I know if I will get a ticket to
            the game?
          </p>

          <p
            class="lead-heading ml-4"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            o If an attendee cancels and a ticket (s) becomes available, you
            will receive an email from
            <a
              style="color: #0068ef"
              href="mailto:NavyFedVisaPadres@vegagroup.com"
            >
              NavyFedVisaPadres@vegagroup.com</a
            >
            letting you know you are approved to register.
            <br />
            o The email will instruct you to complete registration and pay the
            $20 charitable contribution.
            <br />
            o Upon completion, you will receive a confirmation email with
            instructions on how to set up your MLB.com account and MLB Ballpark
            App to receive your tickets.
            <br />
            o We understand that plans change at any time. Therefore, if you are
            on the waitlist, we will not be able to provide a date for when to
            expect the information that you have received a ticket
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            10. My original guest cannot attend; can I bring someone else
            instead?
          </p>

          <p
            class="lead-heading ml-4"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            o If your original guest cannot attend, email
            <a
              style="color: #0068ef"
              href="mailto:NavyFedVisaPadres@vegagroup.com"
            >
              NavyFedVisaPadres@vegagroup.com</a
            >
            to cancel your reservation with the contact information for your new
            guest.
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            11. What entrance should I use, and how do I get to the suite?
          </p>

          <p
            class="lead-heading ml-4"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            o The closest entrance to the Western Metal Suites is the Gaslamp
            Gate, where K Street and Tony Gwynn Street meet.
            <br />
            o Once inside the gate:
            <br />
            <span class="ml-5">
              ▪︎ The entrance is adjacent to the Western Metal Building
            </span>
            <br />
            <span class="ml-5">
              ▪︎ The suites (4A, 4B, 4C, 4D) are on the fourth level
              <br />
            </span>
            <span class="ml-5">
              ▪︎ All signage will say: Western Metal Building
              <br /> </span
            ><span class="ml-5">
              ▪︎ Petco Park event staff can assist with any questions
            </span>
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            12. What are the suite numbers?
          </p>

          <p
            class="lead-heading ml-4"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            o Western Metal Suites (WMB): 4A, 4B, 4C, 4D
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            13. Will we have assigned seating?
          </p>

          <p
            class="lead-heading ml-4"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            o The suites do not have assigned seating.
            <br />
            o There is seating inside the suites and stadium seating.
            <br />
            o All of the seats are first come, first serve for suite guests.
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            14. Can I bring in a bag?
          </p>

          <p
            class="lead-heading ml-4"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            o Yes, but only following the park rules:
            <br />
            <span class="ml-5">
              ▪︎ One small bag no larger than 5”x7”
              <br /> </span
            ><span class="ml-5">
              ▪︎ One Single-compartment clear plastic bag no larger than 12”x
              6”x 12”
              <br /> </span
            ><span class="ml-5">
              ▪︎ ADA/medically necessary items
              <br /> </span
            ><span class="ml-5">
              ▪︎ All bags are subject to search by ballpark security
            </span>
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            15. Who can I contact if I have any questions?
          </p>

          <p
            class="lead-heading ml-4"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            o Please email
            <a
              style="color: #0068ef"
              href="mailto:NavyFedVisaPadres@vegagroup.com"
            >
              NavyFedVisaPadres@vegagroup.com</a
            >
            with any questions or concerns.
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            16. Where can I park?
          </p>

          <p
            class="lead-heading ml-4"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            o The closest parking lot is in the Padres Parkade
            <br />
            o East Village Gate is the best entrance for this parking lot, but
            you must walk across Gallagher Square towards the Western Metal
            Building to get to the suites. <br />
            o We do not have any parking passes.
          </p>

          <p
            class="lead-heading mb-2 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-weight: 700;
              font-size: 1.5em;
              color: black;
            "
          >
            17. What are the terms and conditions for this experience?
          </p>

          <p
            class="lead-heading ml-4"
            style="
              font-family: 'Source Sans 3';
              font-weight: 400;
              font-size: 1.2em;
              color: black;
            "
          >
            o To see the full Terms and Conditions for this offer, click
            <a
              style="color: #0068ef; cursor: pointer"
              target="_self"
              rel="noopener noreferrer"
              @click="openTermsModel()"
            >
              HERE.</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../../views/commons/Navbar19";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      reserveFormAccept: false,
      tkList: {
        // tk_rJtGVRCIkpsvNvy: {
        //   messages: [
        //     "Ticket Price: $15",
        //     "Processing fee of $1.19",
        //     "Limited Time Offer",
        //     // "In Ticket price, $1.28 Fee included",
        //   ],
        // },
        tk_ZXJDjJ3rKdR9wnT: {
          messages: [
            "Ticket Price: $20",
            "Processing fee of $1.38",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
        tk_tQHtXbD8qjRbUlq: {
          messages: [
            "Ticket Price: $50",
            "Processing fee of $2.55",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
        tk_KL1Qk8jLXTDphGW: {
          messages: [
            "Ticket Price: $25",
            "Processing fee of $1.58",
            // "Limited Time Offer",
            // "In Ticket price, $1.28 Fee included",
          ],
        },
      },
      priceIds: {
        // tk_rJtGVRCIkpsvNvy: "price_1KGWsBD0vTZ4QB9c9P53fBs2",
        tk_ZXJDjJ3rKdR9wnT: "price_1KWAVZD0vTZ4QB9cX2roPVIm",
        tk_tQHtXbD8qjRbUlq: "price_1KWAVaD0vTZ4QB9cpiPzUJSd",
        tk_KL1Qk8jLXTDphGW: "price_1KWAVbD0vTZ4QB9cXs1RQlU6",
      },
      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
  },
  methods: {
    openTermsModel: function () {
      this.$refs.termsModal.open();
    },

    changeFavicon(url) {
      console.log("warda", url);
      var link = document.querySelectorAll("link[rel~='icon']");
      if (link && link.length) {
        link.forEach((r) => {
          r.remove();
        });
      }
      link = null;
      var applink = document.querySelectorAll("link[rel~='apple-touch-icon']");
      if (applink && applink.length) {
        applink.forEach((r) => {
          r.remove();
        });
      }
      applink = null;

      var mapplink = document.querySelectorAll("link[rel~='mask-icon']");
      if (mapplink && mapplink.length) {
        mapplink.forEach((r) => {
          r.remove();
        });
      }

      mapplink = null;

      setTimeout(() => {
        const mapplink1 = document.createElement("link");
        mapplink1.rel = "mask-icon";
        mapplink1.color = "#5bbad5";
        mapplink1.href = url + "safari-pinned-tab.svg?v=2";
        document.head.appendChild(mapplink1);

        const applink1 = document.createElement("link");
        applink1.rel = "apple-touch-icon";
        applink1.sizes = "180x180";
        applink1.href = url + "apple-touch-icon.png?v=2";
        document.head.appendChild(applink1);

        const applink12 = document.createElement("link");
        applink12.rel = "apple-touch-icon";
        applink12.sizes = "152x152";
        applink12.href = url + "apple-touch-icon.png?v=2";
        document.head.appendChild(applink12);

        const link1 = document.createElement("link");
        link1.rel = "icon";
        link1.sizes = "16x16";
        link1.href = url + "favicon-16x16.png?v=2";
        document.head.appendChild(link1);

        const link96 = document.createElement("link");
        link96.rel = "icon";
        link96.sizes = "96x96";
        link96.href = url + "favicon-96x96.png?v=2";
        document.head.appendChild(link96);

        const link192 = document.createElement("link");
        link192.rel = "icon";
        link192.sizes = "192x192";
        link192.href = url + "android-icon-192x192.png?v=2";
        document.head.appendChild(link192);

        const link180 = document.createElement("link");
        link180.rel = "apple-touch-icon";
        link180.sizes = "180x180";
        link180.href = url + "apple-icon-180x180.png?v=2";
        document.head.appendChild(link180);

        const link152 = document.createElement("link");
        link152.rel = "apple-touch-icon";
        link152.sizes = "152x152";
        link152.href = url + "apple-icon-152x152.png?v=2";
        document.head.appendChild(link152);

        const link144 = document.createElement("link");
        link144.rel = "apple-touch-icon";
        link144.sizes = "144x144";
        link144.href = url + "apple-icon-144x144.png?v=2";
        document.head.appendChild(link144);

        const link120 = document.createElement("link");
        link120.rel = "apple-touch-icon";
        link120.sizes = "120x120";
        link120.href = url + "apple-icon-120x120.png?v=2";
        document.head.appendChild(link120);

        const link114 = document.createElement("link");
        link114.rel = "apple-touch-icon";
        link114.sizes = "114x114";
        link114.href = url + "apple-icon-114x114.png?v=2";
        document.head.appendChild(link114);

        const link76 = document.createElement("link");
        link76.rel = "apple-touch-icon";
        link76.sizes = "76x76";
        link76.href = url + "apple-icon-76x76.png?v=2";
        document.head.appendChild(link76);

        const link72 = document.createElement("link");
        link72.rel = "apple-touch-icon";
        link72.sizes = "72x72";
        link72.href = url + "apple-icon-72x72.png?v=2";
        document.head.appendChild(link72);

        const link60 = document.createElement("link");
        link60.rel = "apple-touch-icon";
        link60.sizes = "60x60";
        link60.href = url + "apple-icon-60x60.png?v=2";
        document.head.appendChild(link60);

        const link57 = document.createElement("link");
        link57.rel = "apple-touch-icon";
        link57.sizes = "57x57";
        link57.href = url + "apple-icon-57x57.png?v=2";
        document.head.appendChild(link57);

        const link2 = document.createElement("link");
        link2.rel = "icon";
        link2.sizes = "32x32";
        link2.href = url + "favicon-32x32.png?v=2";
        document.head.appendChild(link2);

        const link3 = document.createElement("link");
        link3.rel = "icon";
        link3.href = url + "favicon.ico?v=2";
        document.head.appendChild(link3);
      }, 300);
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    const r = window.location.origin;
    this.changeFavicon(r + "/img/icons6/");
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title:
      "Navy Federal Credit Union Visa Signature® Military Appreciation Month – MLB Hospitality Suites with the Padres - FAQs",

    meta: [
      {
        name: "description",
        content:
          "Navy Federal Credit Union Visa Signature® Military Appreciation Month – MLB Hospitality Suites with the Padres - FAQs",
      },

      {
        name: "keywords",
        content:
          "Navy Federal Credit Union Visa Signature® Military Appreciation Month – MLB Hospitality Suites with the Padres - FAQs",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content: "https://myle.com/navy-federal-visa-padres-faqs",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

#customers {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  /* width: 100%; */
  height: auto;
}
#customers td,
#customers th {
  font-size: 1em;
  border: 1px solid #98bf21;
  padding: 3px 7px 2px 7px;
}
#customers th {
  font-size: 1.2em;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 4px;
  background-color: #a7c942;
  color: #ffffff;
}

.bolded {
  font-weight: bold;
}
</style>
